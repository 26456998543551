import { types } from 'mobx-state-tree';
import { jwtDecode } from 'jwt-decode';
import requests from 'lib/requests';
import { EmailInputStore, InputStore, PasswordInputStore } from 'components/forms/Input';
var TOTPCodeInput = InputStore.named('TOTPCodeInput')
    .props({
    label: 'TOTP Code',
    onChangeHandlingDelay: 0,
})
    .actions(function (self) { return ({
    handleOnKeyDown: function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            self.onEnterPress(self.delayedValue);
        }
        else if (!e.ctrlKey &&
            !['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) &&
            (e.key < '0' || e.key > '9')) {
            e.preventDefault();
            e.stopPropagation();
        }
    },
}); });
export var AuthenticationMixin = types
    .model('AuthenticationMixin', {
    redirectURL: types.maybeNull(types.string),
    error: types.maybeNull(types.string),
    processing: types.optional(types.boolean, false),
    totpCode: types.optional(TOTPCodeInput, function () { return TOTPCodeInput.create({}); }),
    mfa: types.optional(types.boolean, false),
    token: types.maybeNull(types.string),
    // first login setup
    firstLogin: types.optional(types.boolean, false),
    // step 1: agreement
    agreementText: types.maybeNull(types.string),
    agreementCheckboxSelected: types.optional(types.boolean, false),
    agreementIsSigned: types.optional(types.boolean, false),
    // step 2: TOTP setup
    totpSecret: types.maybeNull(types.string),
})
    .volatile(function () { return ({
    setToken: function () { },
    updateURLPath: function () { },
}); })
    .views(function () { return ({
    get emailToSave() {
        return null;
    },
}); })
    .actions(function (self) { return ({
    afterCreate: function () {
        self.totpCode.registerOnEnterPressHandler(self.handleEnterPressOnTOTPCode);
    },
    // init things from global store
    registerSetToken: function (handler) {
        self.setToken = handler;
    },
    registerURLPathUpdater: function (updateURLPath) {
        self.updateURLPath = updateURLPath;
    },
    setRedirectURL: function (redirectURL) {
        self.redirectURL = redirectURL || null;
    },
    // agreement logic
    toggleAgreementCheckBox: function () {
        self.agreementCheckboxSelected = !self.agreementCheckboxSelected;
    },
    signAgreement: function () {
        self.setProcessing(true);
        // @ts-ignore: typescript is not cannot determine our requests lib properties correctly
        requests.POST({
            url: '/m/api/v1/user-agreement',
            authToken: self.token,
            onFailure: function (response, errors) { return self.setError(errors); },
            onSuccess: function () { return setTimeout(self.getTotp, 200); },
        });
    },
    // totp setup
    getTotp: function () {
        // @ts-ignore: ts cannot determine our requests lib properties correctly. should be fixes by reworking requests
        requests.GET({
            url: '/m/api/v1/auth/totp',
            authToken: self.token,
            onFailure: function (response, errors) { return self.setError(errors); },
            onSuccess: self.saveTotpSecret,
            onFinish: function () { return self.setProcessing(false); },
        });
    },
    saveTotpSecret: function (response, responseBody) {
        self.totpSecret = responseBody.data.provisioning_uri;
    },
    confirmTotpSecret: function () {
        self.setProcessing(true);
        self.totpCode.setError();
        // @ts-ignore: ts cannot determine our requests lib properties correctly. should be fixes by reworking requests
        requests.POST({
            url: '/m/api/v1/auth/totp',
            body: self.totpCode.value,
            authToken: self.token,
            onFailure: function (response, errors) { return self.setError(errors); },
            onSuccess: function () { return self.onSuccessLogin(self.token); },
            onFinish: function () { return self.setProcessing(false); },
        });
    },
    submitTotpCode: function () {
        self.setProcessing(true);
        // @ts-ignore: ts cannot determine our requests lib properties correctly. should be fixes by reworking requests
        requests.POST({
            url: '/m/api/v1/auth/tokens/mgmt-token',
            body: { totp: self.totpCode.value },
            authToken: self.token,
            onFailure: function (response, errors) { return self.setError(errors); },
            onSuccess: function (response, responseBody) { return self.onSuccessLogin(responseBody.data.token); },
            onFinish: function () { return self.setProcessing(false); },
        });
    },
    handleEnterPressOnTOTPCode: function () {
        if (self.mfa) {
            self.submitTotpCode();
        }
        else {
            self.confirmTotpSecret();
        }
    },
    // common logic
    onSuccessLogin: function (token) {
        var email;
        if (self.emailToSave === null) {
            // @ts-ignore: If there is no sub claim, we fucked up and missing key is the last thing to care.
            email = jwtDecode(token).sub;
        }
        else {
            email = self.emailToSave;
        }
        self.setToken(token, email);
        self.updateURLPath(self.redirectURL || '/');
    },
    setError: function (errors) {
        if (errors === undefined) {
            errors = 'Login failed. Please, try again.';
        }
        else if (typeof errors !== 'string') {
            errors = errors.join('. ');
        }
        self.error = errors;
    },
    setProcessing: function (value) {
        self.processing = value;
    },
    startFirstLoginSetup: function (responseBody) {
        if (responseBody) {
            self.token = responseBody.data.token;
            self.agreementText = responseBody.data.agreement;
            self.firstLogin = true;
        }
        else {
            self.token = null;
            self.agreementText = null;
            self.firstLogin = false;
        }
    },
    askTotpCode: function (responseBody) {
        self.token = responseBody.data.token;
        self.mfa = true;
    },
    processError: function (response, errors, responseBody) {
        if (response.status < 400) {
            self.setError(errors);
        }
        else if (response.status === 428 && responseBody.data.precondition === 'user-agreement') {
            self.startFirstLoginSetup(responseBody);
        }
        else if (response.status === 428 && responseBody.data.precondition === 'totp') {
            self.askTotpCode(responseBody);
        }
        else {
            self.setError();
        }
    },
}); });
var CredentialsAuthForm = AuthenticationMixin.named('CredentialsAuthForm')
    .props({
    email: types.optional(EmailInputStore, function () { return EmailInputStore.create({ autoFocus: true }); }),
    password: types.optional(PasswordInputStore, function () { return PasswordInputStore.create(); }),
    authenticating: false,
})
    .views(function (self) { return ({
    get emailToSave() {
        return self.email.value;
    },
}); })
    .actions(function (self) { return ({
    afterCreate: function () {
        self.password.registerOnEnterPressHandler(self.login);
    },
    setProcessing: function (value) {
        self.processing = value;
        self.email.setDisabled(value);
        self.password.setDisabled(value);
    },
    login: function () {
        self.setProcessing(true);
        self.error = null;
        // @ts-ignore: ts cannot determine our requests lib properties correctly. should be fixes by reworking requests
        requests.POST({
            url: '/m/api/v1/auth/tokens/mgmt-token',
            body: {
                email: self.email.value,
                password: self.password.value,
            },
            onFailure: self.processError,
            onSuccess: function (response, responseBody) {
                self.onSuccessLogin(responseBody.data.token);
            },
            onFinish: function () {
                self.setProcessing(false);
            },
        });
    },
}); })
    .views(function (self) { return ({
    get filled() {
        return Boolean(self.email.isDone() && self.password.isDone());
    },
}); });
export default CredentialsAuthForm;
