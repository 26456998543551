import { types } from 'mobx-state-tree';
import Store from 'stores/Store';
import Swagger from 'stores/Swagger';

export default types
  .model('OrganizationInfo', {
    name: types.maybeNull(types.string),
    displayName: types.maybeNull(types.string),
    owner: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    instance_status: types.maybeNull(types.string),
    Swagger: types.optional(Swagger, () => Swagger.create({ url: '/m/api/v1/swagger.json' })),
    loading: false,
  })
  .actions((self) => ({
    afterCreate: () => {
      self.Swagger.setTokenProvider(() => Store.Profile.token);
    },
    setDisplayName(displayName) {
      self.displayName = displayName;
    },
    saveInfo(info) {
      // this is not needed now
      // self.id = info.id
      self.name = info.name;
      self.displayName = info.display_name;
      self.owner = info.owner;
      self.createdAt = info.created_at;
      self.instance_status = info.status;
    },
    stopLoading() {
      self.loading = false;
    },
    fetch() {
      self.loading = true;
      Store.TransportLayer.get({
        url: '/m/api/v1/account',
        onSuccess: (response, response_data) => {
          self.saveInfo(response_data.data);
        },
        onFinish: self.stopLoading,
      });
    },
  }))
  .views((self) => ({
    get loaded() {
      return self.name !== null;
    },
  }));
