export const UserRoles = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const SystemUserRole = 'SYSTEM_USER';

export const UserStates = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  INVITED: 'invited',
};

export const ApplicationStates = {
  Running: 'active',
  Stopped: 'stopped',
};

export const ServicesFilters = {
  Critical: 'critical',
  Warning: 'warning',
  Operational: 'ok',
};

export const AppSecretStates = {
  LATEST: 'Active',
  RETIRING: 'Retiring',
  DEACTIVATED: 'Deactivated',
};

export const InstanceStates = {
  Requested: 'requested',
  Deleting: 'deleting',
  Ready: 'ready',
};

export const InstanceReadinessStages = {
  unknown: -1,
  notHostApp: 10,
  hostAppExists: 20,
  hostExists: 30,
};

// export const ServicesStates = {
//   Running: 'active',
//   Stopped: 'stopped',
// };

export const InstanceStateToClass = {
  requested: 'offline',
  creating: 'warning',
  ready: 'success',
  deleting: 'danger',
};

export const UnexpectedErrorMessage = [
  'Oops... We are sorry, but an unexpected error had happened.',
  'Please contact ITLook support team.',
];

export const NotificationTypes = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
};

// export const ChartColors = ['#53a2d0', '#d05353', '#1a936f', '#d0a553'];
export const ChartColors = [
  '#B5D8ED',
  '#E5C1AC',
  '#8DD0BC',
  '#EFD5B1',
  '#D5CFDF',
  '#E0BDE3',
  '#E8C0BD',
  '#E9E6C3',
  '#BDC2B2',
  '#F2F2F2',
];

export const LoginActions = Object.fromEntries(
  [
    {
      urlValue: 'logout',
      notificationType: NotificationTypes.success,
      msg: 'You successfully logout. Hope to see you soon.',
      includeRedirectURL: false,
    },
    {
      urlValue: 'renew-token',
      notificationType: NotificationTypes.info,
      msg: 'Your token is expired or invalid. Please login again.',
      includeRedirectURL: true,
    },
    {
      urlValue: 'password-is-updated',
      notificationType: NotificationTypes.success,
      msg: 'Your password is successfully updated.',
      includeRedirectURL: true,
    },
  ].map((i) => [i.urlValue, i])
);

export const TimeFormat = 'HH:mm';
export const DateFormat = `MMM D YYYY, ${TimeFormat}`;

export const KeyCodes = {
  TAB: 9,
  ENTER: 13,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
};

export const HOST_APP_TYPE = 'std::Hosts:1';
