import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { ExistsOnlyForAdmin } from 'pages/NotFound';
import FormItem from 'components/forms/FormItem';
import Input from 'components/forms/Input';
import { ContainerBox, PageHeader } from 'components/Page';
import { SidedPanel, LeftPanel } from 'components/layout/Panels';
import UserInviteS from 'pages/Organization/Users/UserInviteS';

const UserInvite = inject('org')(
  observer((props) => {
    const [form] = useState(UserInviteS.create({}));

    const navigate = useNavigate();

    useEffect(() => {
      form.linkUsersStore(props.org.Users);
      form.linkNavigator(navigate);
    }, []);

    return (
      <ContainerBox>
        <PageHeader to="/organization/users" title="Users" subTitle="Invite" documentTitle="Invite user" />
        <SidedPanel>
          <LeftPanel>
            <form
              className="form-box"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Input key="email" store={form.email} />
              <Input key="firstName" store={form.firstName} />
              <Input key="lastName" store={form.lastName} />
              <Input key="role" store={form.role} />
              <div className="message-placeholder">{form.errors}</div>
              <div className="form-box-line">
                <FormItem key="invite">
                  <button
                    type="button"
                    className="btn-primary btn-block"
                    onClick={form.sendInvitation}
                    disabled={!form.filled || form.requesting}
                  >
                    Invite
                  </button>
                </FormItem>
                <FormItem key="cancel">
                  <Link className="btn btn-default btn-block" to="/organization/users">
                    Cancel
                  </Link>
                </FormItem>
              </div>
            </form>
          </LeftPanel>
        </SidedPanel>
      </ContainerBox>
    );
  })
);

export default () => (
  <ExistsOnlyForAdmin>
    <UserInvite />
  </ExistsOnlyForAdmin>
);
