import React from 'react';
import { observer } from 'mobx-react';

import { NewTerminalSession } from 'pages/TerminalsModal/NewTerminalSession';
import CancelScriptRun from 'pages/Instances/RecordView/TopBarControls/CancelScriptRun';
import EditButton from 'pages/Instances/RecordView/TopBarControls/EditButton';
import DeleteButton from 'pages/Instances/RecordView/TopBarControls/DeleteButton';

import Store from 'stores/Store';

export default observer((props) => (
  <>
    <NewTerminalSession record={props.record} Terminals={Store.Terminals} />
    <CancelScriptRun record={props.record} />
    <EditButton record={props.record} />
    <DeleteButton record={props.record} />
  </>
));
