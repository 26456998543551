import React from 'react';
import { Navigate } from 'react-router-dom';
export var getLoginURL = function (actionParam, includeRedirectURL) {
    if (includeRedirectURL === void 0) { includeRedirectURL = false; }
    var query = new URLSearchParams({});
    if (actionParam) {
        query.set('action', actionParam);
    }
    if (includeRedirectURL) {
        query.set('redirect_url', window.location.pathname);
    }
    return "/?".concat(query.toString());
};
export default (function () { return React.createElement(Navigate, { to: getLoginURL(undefined, true), replace: true }); });
