import { types } from 'mobx-state-tree';

import * as queryString from 'lib/query-string';
import { InputStore } from 'components/forms/Input';

export default types
  .model('UsersPage', {
    filters: types.optional(types.map(types.boolean), { active: true, suspended: true, invited: true }),
    sortBy: 'fullName',
    sortBack: false,
    nameFilter: types.optional(InputStore, () =>
      InputStore.create({
        formItemClassName: 'search',
        type: 'search',
        placeholder: 'Search',
        label: 'Search',
      })
    ),
    email: types.maybeNull(types.string),
  })
  .volatile(() => ({
    // eslint-disable-next-line no-unused-vars
    navigate: (url) => {},
  }))
  .actions((self) => ({
    registerNavigator(func) {
      self.navigate = func;
    },
    loadFromQuery(rawQueryString) {
      const query = queryString.parse(rawQueryString);
      if (query.search) {
        self.nameFilter.setValue(query.search);
        self.query = query.search;
      }
      if (query.sort_by) {
        self.sort_by = query.sort_by;
      }
      if (query.sort_back === 'true') {
        self.sortBack = true;
      }
      self.email = query.selected || null;
      ['active', 'invited', 'suspended'].forEach((f) => {
        if (query[f] === 'false') {
          self.filters.set(f, false);
        }
      });
    },
    toggleFilter(filter) {
      self.filters.set(filter, !self.filters.get(filter));
    },
    onSortChange(value, sortBack) {
      self.sortBy = value;
      self.sortBack = sortBack;
    },
    search() {
      self.navigate(self.usersListURL);
    },
    setUserToShow(email) {
      if (self.email === email) {
        self.email = null;
      } else {
        self.email = email;
      }
    },
  }))
  .views((self) => ({
    get showActive() {
      return self.filters.get('active');
    },
    get showSuspended() {
      return self.filters.get('suspended');
    },
    get showInvited() {
      return self.filters.get('invited');
    },
    get usersListURL() {
      const query = Object.fromEntries(self.filters);
      query.sort_back = self.sortBack;
      query.sort_by = self.sortBy;
      if (self.nameFilter.value !== '') {
        query.q = self.nameFilter.value;
      }
      if (self.email !== null) {
        query.selected = self.email;
      }
      return `/organization/users?${queryString.stringify(query)}`;
    },
  }));
