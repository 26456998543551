import React, { useEffect } from 'react';

import { inject, observer } from 'mobx-react';

import Input, { InputStore, EmailInputStore } from 'components/forms/Input';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';

export default inject('org')(
  observer((props) => {
    const orgName = InputStore.create({
      label: 'Name',
      disabled: true,
    });
    const orgDisplayName = InputStore.create({
      label: 'Display name',
      disabled: true,
    });
    const ownerEmail = EmailInputStore.create({
      label: 'Owner email',
      disabled: true,
    });

    useEffect(() => {
      if (!props.org.Info.loading) {
        props.org.Info.fetch();
      }
    }, []);

    useEffect(() => {
      if (props.org.Info.name !== null) {
        orgName.setValue(props.org.Info.name);
        orgDisplayName.setValue(props.org.Info.displayName);
        ownerEmail.setValue(props.org.Info.owner || '');
      }
    }, [props.org.Info.name]);

    return (
      <SidedPanel>
        <LeftPanel>
          <form className="form-box">
            <Input key="name" store={orgName} />
            <Input key="displayName" store={orgDisplayName} />
            <Input key="email" store={ownerEmail} />
          </form>
        </LeftPanel>
      </SidedPanel>
    );
  })
);
