import { types } from 'mobx-state-tree';

import { UserRoles, UserStates } from 'Constants';
import { InputStore, EmailInputStore, PasswordInputStore, DropdownSelectorStore } from 'components/forms/Input';

export default types
  .model('UserCreateForm', {
    email: types.optional(EmailInputStore, () => EmailInputStore.create()),
    password: types.optional(PasswordInputStore, () => PasswordInputStore.create({ schema: {} })),
    firstName: types.optional(InputStore, () => InputStore.create({ label: 'First name' })),
    lastName: types.optional(InputStore, () => InputStore.create({ label: 'Last name' })),
    role: types.optional(DropdownSelectorStore, () =>
      DropdownSelectorStore.create({
        label: 'Role',
        options: Object.values(UserRoles).map((userRole) => ({ value: userRole })),
        noDefaultPlaceholder: true,
        selected: UserRoles.USER,
      })
    ),
    errors: types.array(types.string),
    // the process of creating an user
    creating: false,
  })
  .volatile(() => ({
    usersStore: null,
    navigate: () => {},
  }))
  .actions((self) => ({
    linkUsersStore(store) {
      self.usersStore = store;
    },
    linkNavigator(func) {
      self.navigate = func;
    },
    setCreatingFlag(value) {
      self.creating = value;
      self.email.setDisabled(value);
      self.password.setDisabled(value);
      self.firstName.setDisabled(value);
      self.lastName.setDisabled(value);
      self.role.setDisabled(value);
    },
    setCreateError(errors) {
      self.errors = errors;
      self.creating = false;
    },
    onSuccess() {
      self.navigate('/organization/users');
    },
    createNewUser() {
      self.setCreatingFlag(true);

      self.usersStore.createNew(
        self.email.value,
        self.firstName.value,
        self.lastName.value,
        self.password.value,
        self.role.value,
        self.onSuccess,
        (resp, errors) => {
          self.setCreatingFlag(false);
          self.setCreateError(errors);
        }
      );
    },
    validateEmailExistenceInTheSystem(value) {
      if (!value) {
        return false;
      }

      const newPromise = new Promise((resolve) => {
        const onFetchComplete = (user) => {
          if (user !== undefined) {
            if (user.state === UserStates.INVITED) {
              resolve(
                'The invitation to user with such email has been sent. If you want ' +
                  'to create a record manually instead, you should revoke invitation first.'
              );
            } else {
              resolve('The user with such email already exists.');
            }
          }
          resolve(false);
        };

        self.usersStore.getFromAPI(value, onFetchComplete);
      });

      return newPromise;
    },
    afterCreate() {
      self.email.addInputValidator(self.validateEmailExistenceInTheSystem);
    },
  }))
  .views((self) => ({
    get filled() {
      return self.email.isDone() && self.password.isDone() && self.firstName !== null && self.lastName !== null;
    },
  }));
