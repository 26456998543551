import React from 'react';

import { TabViewsSwitcher, TabView, TabViewContent } from 'components/TabsPageView';
import { PageHeader } from 'components/Page';

import OrganizationInfo from 'pages/Organization/OrganizationInfo';
import UsersList from 'pages/Organization/Users/UsersList';
import OrganizationSettings from 'pages/Organization/Settings';

export default () => (
  <TabViewsSwitcher>
    {/* TODO: We should be able somehow to make page header dependent on active tab view */}
    <PageHeader title="Administration" />
    <TabView title="Organization" key="info" url="info">
      <TabViewContent>
        <OrganizationInfo />
      </TabViewContent>
    </TabView>
    <TabView title="Users" key="users" url="users">
      <TabViewContent>
        <UsersList />
      </TabViewContent>
    </TabView>

    {!process.env.DISABLE_NOT_WORKING && (
      <TabView title="Settings" key="settings" url="settings" onlyAdmin>
        <TabViewContent>
          <OrganizationSettings />
        </TabViewContent>
      </TabView>
    )}
  </TabViewsSwitcher>
);
