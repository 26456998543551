import { types } from 'mobx-state-tree';

import { UserRoles } from 'Constants';
import Store from 'stores/Store';

const AccessSettings = types
  .model('AccessSettings', {
    OAuth2Enabled: types.optional(types.boolean, true),
    OAuth2Only: types.optional(types.boolean, false),
    wildcardDomains: types.optional(types.array(types.string), []),
    wildcardNewUserRole: types.optional(types.string, UserRoles.USER),
  })
  .actions((self) => ({
    saveValue(optionName, value, onSuccess, onFailure) {
      const optionURL = `/m/api/v1/v0-settings/access/${optionName}`;
      if (typeof value === 'boolean' && value === false) {
        Store.TransportLayer.delete({
          url: optionURL,
          onSuccess: onSuccess,
          onFailure: onFailure,
        });
      } else {
        Store.TransportLayer.post({
          url: optionURL,
          body: JSON.stringify(value),
          onSuccess: onSuccess,
          onFailure: onFailure,
        });
      }
    },
    toggleOAuth2Enabled() {
      self.OAuth2Enabled = !self.OAuth2Enabled;
      if (!self.OAuth2Enabled && self.OAuth2Only) {
        self.OAuth2Only = false;
      }
      this.saveValue('oauth2-enabled', self.OAuth2Enabled);
    },
    toggleOAuth2Only() {
      if (!(!self.OAuth2Only && !self.OAuth2Enabled)) {
        self.OAuth2Only = !self.OAuth2Only;
        this.saveValue('oauth2-only', self.OAuth2Only);
      }
    },
    addWildcardDomain(domain, onSuccess, onFailure) {
      self.wildcardDomains.push(domain);
      self.saveWildcardDomains(onSuccess, onFailure);
    },
    deleteWildcardDomain(index) {
      self.wildcardDomains.splice(index, 1);
      self.saveWildcardDomains();
    },
    saveWildcardDomains(onSuccess, onFailure) {
      this.saveValue('wildcard-domains', self.wildcardDomains, onSuccess, onFailure);
    },
    setWildcardNewUserRole(role) {
      self.wildcardNewUserRole = role;
      this.saveValue('wildcard-new-user-role', { role: self.wildcardNewUserRole });
    },
  }));

const Settings = types
  .model('Settings', {
    access: types.optional(AccessSettings, () => AccessSettings.create({})),
    loading: types.optional(types.boolean, false),
    loaded: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    fetch() {
      self.loading = true;
      Store.TransportLayer.get({
        url: '/m/api/v1/v0-settings',
        onSuccess: (response, response_data) => self.finishLoading(response_data),
        onFailure: () => self.finishLoading(),
      });
    },
    finishLoading(response_data) {
      self.loading = false;
      const settings = response_data.data;
      if (settings) {
        self.loaded = true;
        self.access = AccessSettings.create({
          OAuth2Enabled: settings.access.oauth2_enabled,
          OAuth2Only: settings.access.oauth2_only,
          wildcardDomains: settings.access.wildcard_domains,
          wildcardNewUserRole: settings.access.wildcard_new_user_role,
        });
      }
    },
  }));

export default Settings;
