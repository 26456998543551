import 'regenerator-runtime/runtime';
import 'xterm/css/xterm.css';
import variables from 'styles/_variables.module.scss';

import React, { useEffect, useState } from 'react';
import { KeyCodes } from 'Constants';

const TerminalSearch = (props) => {
  const search = props.searchAddon || props.session.connection.addons.getSearchAddon();
  const [pattern, setPattern] = useState('');

  const searchOption = {
    decorations: {
      matchBorder: variables.chartOrange3,
      activeMatchBackground: variables.chartOrange2,
    },
  };

  const findNext = () => {
    search.findNext(pattern, searchOption);
  };

  const findPrev = () => {
    search.findPrevious(pattern, searchOption);
  };

  useEffect(() => {
    if (pattern) {
      findNext();
    }
  }, [pattern]);

  const onChange = (e) => {
    setPattern(e.target.value);
  };
  const onKeyDown = (e) => {
    if (e.keyCode === KeyCodes.ARROW_UP) {
      if (pattern) {
        findPrev();
      }
      e.preventDefault();
    } else if (e.keyCode === KeyCodes.ENTER || e.keyCode === KeyCodes.ARROW_DOWN) {
      if (pattern) {
        findNext();
      }
      e.preventDefault();
    }
  };
  return (
    <div className="terminal-search-container">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="form-box">
          <div className="form-item terminal-search">
            <div className="item-container">
              <input type="text" placeholder="Search" onChange={onChange} onKeyDown={onKeyDown} />
              <button type="button" className="btn-only-icon prev" onClick={findPrev} />
              <button type="button" className="btn-only-icon next" onClick={findNext} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export { TerminalSearch };
