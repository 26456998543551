var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { UUID4 } from 'lib/uuid-utils';
var Radio = function (props) {
    // special ID to associate <input /> with <label />
    var someId = UUID4();
    return (React.createElement("div", { className: "radio bounce" },
        React.createElement("input", { type: "radio", id: someId, value: props.value, disabled: props.disabled, checked: props.selected, onClick: function () { return props.onSelect && props.onSelect(props.value); }, 
            // add dummy onChange handler to suppress warning at browser console
            onChange: function () { } }),
        React.createElement("svg", { viewBox: "0 0 18 18" },
            React.createElement("circle", { cx: "9", cy: "9", r: "4" })),
        React.createElement("label", { htmlFor: someId }, props.label)));
};
export default (function (props) {
    var className;
    if (props.horizontalView) {
        className = 'fieldset-on-line';
    }
    else {
        className = 'fieldset';
    }
    var selected = props.selected;
    if (selected === undefined) {
        selected = props.items[0].value;
        if (props.onSelect) {
            props.onSelect(selected);
        }
    }
    return (React.createElement("fieldset", { className: className }, props.items.map(function (item) { return (React.createElement(Radio, __assign({ key: item.value }, item, { selected: item.value === selected, onSelect: props.onSelect }))); })));
});
