import React from 'react';

import { inject, observer } from 'mobx-react';
import copy from 'copy-to-clipboard';

import * as ContentHeader from 'components/ContentHeader';
import Icon from 'components/Icon';

export default inject(
  'store',
  'instance'
)(
  observer((props) => {
    const pictureURL = props.store.ApplicationTypes.getPicture(props.record.app_1.applicationType);
    const clsName = props.record.app_1.state === 'active' ? 'status text-success' : 'status text-danger';
    return (
      <ContentHeader.Container>
        <ContentHeader.ColumnWithIconItem url={pictureURL} />

        <ContentHeader.Column>
          <ContentHeader.Item>
            <span>Status:</span>
            <span className={clsName}>{props.record.app_1.state}</span>
          </ContentHeader.Item>
        </ContentHeader.Column>

        <ContentHeader.Column>
          <ContentHeader.Item>
            <span>ID:</span>
            {props.record.id.slice(0, 8)}
            <button
              type="button"
              onClick={() => copy(props.record.id)}
              title="Copy to Clipboard"
              className="btn btn-link btn-copy"
            >
              <Icon className="copy" />
            </button>
          </ContentHeader.Item>
          {props.record.app_1.externalID && (
            <ContentHeader.Item>
              <span>External ID:</span>
              {props.record.app_1.externalId}
            </ContentHeader.Item>
          )}
        </ContentHeader.Column>

        <ContentHeader.Column>
          <ContentHeader.Item>
            <span>Description:</span>
            {props.record.app_1.description}
          </ContentHeader.Item>
        </ContentHeader.Column>

        <ContentHeader.Column>
          <ContentHeader.Item>
            <span>Created at:</span>
            {props.record.createdAt.format('MMM D YYYY, HH:mm')}
          </ContentHeader.Item>
        </ContentHeader.Column>
        <ContentHeader.Column>
          <ContentHeader.Item>
            <span>Updated at:</span>
            {props.record.updatedAt.format('MMM D YYYY, HH:mm')}
          </ContentHeader.Item>
        </ContentHeader.Column>
      </ContentHeader.Container>
    );
  })
);
