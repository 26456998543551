import 'regenerator-runtime/runtime';

import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react';

import ClickAwayListener from 'react-click-away-listener';

import Input from 'components/forms/Input';
import 'xterm/css/xterm.css';

const LinkForDownloading = (props) => {
  useEffect(() => {
    props.fileRef.current.click();
    window.URL.revokeObjectURL(props.fileContent);
    props.form.setDisabled(false);
  }, []);

  return <a ref={props.fileRef} download={props.download} href={props.href} />;
};

const FileInfo = (props) => {
  // Link to source: https://gist.github.com/lanqy/5193417
  const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0 || !bytes) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  };

  let message = <span>&nbsp;</span>;
  if (props.exists) {
    message = (
      <>
        <span>{props.mimeType}</span>
        <span>{bytesToSize(props.bytes)}</span>
      </>
    );
  }

  return <span className="help-text">{message}</span>;
};

const FileDownloader = observer((props) => {
  const fileDownloader = props.fileDownloader;
  const { path } = fileDownloader;
  const fileRef = useRef(null);

  const filename = path ? path.slice(path.lastIndexOf('/') + 1) : '';

  return (
    <ClickAwayListener onClickAway={props.onClose}>
      <div className="file-download-modal">
        <div className="header">
          <div className="title">File Download</div>
          <div className="close-btn" onClick={props.onClose} />
        </div>
        <div className="body">
          <form>
            <div className="form-box">
              <div className="form-item">
                <Input store={fileDownloader.form}>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={fileDownloader.downloadFile}
                    disabled={!fileDownloader.canDownload}
                  >
                    Download
                  </button>
                </Input>
                {fileDownloader.content && (
                  <LinkForDownloading
                    fileRef={fileRef}
                    download={filename}
                    href={fileDownloader.content}
                    form={fileDownloader.form}
                  />
                )}
                {!fileDownloader.error && (
                  <FileInfo
                    exists={fileDownloader.exists}
                    mimeType={fileDownloader.mimeType}
                    bytes={fileDownloader.bytes}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="footer" />
      </div>
    </ClickAwayListener>
  );
});

const FileUploader = (props) => (
  <div className="file-upload-modal" style={{ display: 'none' }}>
    <div className="header">
      <div className="title">File Upload</div>
      <div className="close-btn" onClick={props.onClose} />
    </div>
    <div className="body">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-box">
          <div className="item-container">
            <div className="file-upload-select">
              <div className="file-select-button">Choose File or Drag and Drop Here</div>
              <div className="file-select-name" style={{ display: 'none' }}>
                No file chosen...
              </div>
              <Input key="file-to-upload" store={props.fileUploader} />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div className="footer" />
  </div>
);

export { FileDownloader, FileUploader };
