import { getParentOfType, types } from 'mobx-state-tree';

import Store from 'stores/Store';
import { UserStates } from 'Constants';

const DeletedState = 'archived';

export const User = types
  .model('User', {
    email: types.string,
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    lastLogin: types.optional(types.string, ''),
    state: types.string,
    role: types.string,
    processing: false,
  })
  .views((self) => ({
    get fullName() {
      return `${self.first_name} ${self.last_name}`;
    },
    get isDeleted() {
      // this flag is used to disable sidebar
      return self.state === DeletedState;
    },
  }))
  .actions((self) => ({
    stopProcessing() {
      self.processing = false;
    },
    toggleActiveState() {
      self.processing = true;
      let method;
      let newState;
      if (self.state === UserStates.ACTIVE) {
        method = Store.TransportLayer.put;
        newState = UserStates.SUSPENDED;
      } else {
        method = Store.TransportLayer.delete;
        newState = UserStates.ACTIVE;
      }
      method({
        url: `/m/api/v1/users/${self.email}/suspend`,
        onSuccess: () => {
          self.setState(newState);
          self.stopProcessing();
        },
        onFailure: self.stopProcessing,
      });
    },
    delete() {
      Store.TransportLayer.delete({
        url: `/m/api/v1/users/${self.email}`,
        onSuccess: () => {
          self.setState(DeletedState);
          self.deleteFromParent();
        },
      });
    },
    deleteFromParent() {
      getParentOfType(self, Users).deleteFromStore(self.email);
    },
    resendInvitation() {
      Store.TransportLayer.put({
        url: `/m/api/v1/users/${self.email}/resend-invitation`,
      });
    },
    setState(state) {
      self.state = state;
    },
  }));

const Users = types
  .model('Users', {
    items: types.optional(types.map(User), {}),
    loaded: false,
    loading: false,
  })
  .actions((self) => ({
    getLoadedUser(email) {
      return self.items.get(email);
    },
    getFromAPI(email, onFetchComplete) {
      Store.TransportLayer.get({
        url: `/m/api/v1/users/${email}`,
        onSuccess: (res, data) => onFetchComplete(data.data),
        onFailure: () => onFetchComplete(),
      });
    },
    setLoaded() {
      self.loaded = true;
    },
    setLoading() {
      self.loading = false;
    },
    load() {
      self.loading = true;
      Store.TransportLayer.get({
        url: '/m/api/v1/users?detailed=true',
        onSuccess: (response, response_data) => {
          self.pushItems(response_data.data);
          self.setLoaded();
          self.setLoading();
        },
      });
    },
    pushItems(data) {
      self.items = Object.fromEntries(data.users.map((u) => [u.email, User.create(u)]));
    },
    createNew(email, firstName, lastName, password, role, onSuccess, onFailure) {
      Store.TransportLayer.post({
        url: `/m/api/v1/users`,
        body: {
          email: email,
          first_name: firstName,
          last_name: lastName,
          password: password,
          role: role,
        },
        onSuccess: () => {
          // reload users
          self.load();
          if (onSuccess !== undefined) {
            onSuccess();
          }
        },
        onFailure: onFailure,
      });
    },
    invite(email, firstName, lastName, role, onSuccess) {
      const data = {};
      data[email] = {
        first_name: firstName,
        last_name: lastName,
        role: role,
      };
      Store.TransportLayer.post({
        url: '/m/api/v1/users/invite',
        body: data,
        onSuccess: onSuccess,
      });
    },
    verifyInvite(token, onSuccess, onFailure) {
      Store.TransportLayer.get({
        url: '/m/api/v1/auth/tokens',
        authToken: token,
        onSuccess: onSuccess,
        onFailure: onFailure,
      });
    },
    deleteFromStore(email) {
      self.items.delete(email);
    },
    completeInvitation(token, userData, onSuccess, onFailure) {
      Store.TransportLayer.post({
        url: '/m/api/v1/users',
        authToken: token,
        body: userData,
        onSuccess: onSuccess,
        onFailure: onFailure,
      });
    },
  }))
  .views((self) => ({
    list() {
      return Array.from(self.items.values());
    },
  }));

export default Users;
