import 'regenerator-runtime/runtime';

import React from 'react';
import { observer } from 'mobx-react';

import Icon from 'components/Icon';

import 'xterm/css/xterm.css';

const NewTerminalSession = observer((props) => {
  const record = props.record.data;

  if (!(record.has('std::host/Host:1') || record.has('std::host/Container:1'))) {
    return null;
  }

  return (
    <button type="button" className="btn btn-success" onClick={() => props.Terminals.startNewSession(props.record)}>
      <Icon className="terminal-btn" />
      New session
    </button>
  );
});

export { NewTerminalSession };
