import React, { useEffect, useState } from 'react';

import { inject, observer } from 'mobx-react';
import copy from 'copy-to-clipboard';

import { AppSecretStates } from 'Constants';
import Icon from 'components/Icon';
import { Spinner } from 'components/Loader';

const SecretState2Class = Object.fromEntries([
  [AppSecretStates.LATEST, 'latest'],
  [AppSecretStates.RETIRING, 'retired'],
  [AppSecretStates.DEACTIVATED, 'deactivated'],
  ['New', 'latest'],
]);

const RawSecretComponent = (props) => (
  <div className="key-item">
    <div className={`key-icon ${SecretState2Class[props.state]}`} />
    <div className="key-details">
      <div className="key-info">{props.keyInfo}</div>
      <div className="key-additions">
        <span key="state">State: {props.state}</span>
        {props.issuedAt && <span key="issued">Issued at: {props.issuedAt.format('MMM D YYYY, HH:mm')}</span>}
        {props.revokedAt && <span key="revoked">Revoked at: {props.revokedAt.format('MMM D YYYY, HH:mm')}</span>}
      </div>
    </div>
    {props.children}
  </div>
);

const JustCreatedSecret = (props) => (
  <RawSecretComponent keyInfo={props.secret} state="New">
    <div className="key-action">
      <button type="button" className="btn btn-link btn-copy">
        <Icon className="copy" onClick={() => copy(props.secret)} />
      </button>
    </div>
  </RawSecretComponent>
);

const Secret = (props) => (
  <RawSecretComponent
    state={props.secret.state}
    keyInfo={props.secret.id}
    issuedAt={props.secret.issuedAt}
    revokedAt={props.secret.revokedAt}
  />
);

const SecretsRotationButton = observer((props) => {
  let finishButton;
  if (!props.secrets.justCreatedSecretId) {
    if (props.secrets.activeCount === 0) {
      // this looks like an error. parent component should handle the situation
      return null;
    }
    finishButton = props.secrets.activeCount !== 1;
  } else {
    finishButton = true;
  }

  const onClick = finishButton ? props.secrets.finishRotation : props.secrets.startRotation;

  return (
    <div className="filters-box">
      <form className="form-box">
        <button type="button" className="btn btn-primary" onClick={onClick} disabled={props.secrets.processing}>
          {finishButton ? 'Finish' : 'Start'} Rotation
        </button>
      </form>
    </div>
  );
});

@observer
class Secrets extends React.Component {
  constructor(props) {
    super(props);
    this.secrets = this.props.application.Secrets;
  }

  componentDidMount() {
    if (!this.secrets.loading) {
      this.secrets.fetch();
    }
  }

  render() {
    if (!this.secrets.loaded) {
      return <Spinner />;
    }
    const secrets = this.secrets.items.map((s) => <Secret secret={s} key={s.id} />);
    let message;

    if (this.secrets.items.length === 0) {
      message = 'No secrets. Agent record may be corrupted, please contact ITLook support team.';
    }

    return (
      <>
        <SecretsRotationButton secrets={this.secrets} />
        <div className="keys-container">
          {this.secrets.justCreatedSecret && (
            <JustCreatedSecret
              key="justCreated"
              secretId={this.secrets.justCreatedSecretId}
              secret={this.secrets.justCreatedSecret}
            />
          )}
          {message}
          {secrets}
        </div>
      </>
    );
  }
}

const AgentSecrets = inject('instance')(
  observer((props) => {
    const [app, setApp] = useState(null);

    useEffect(() => {
      if (!props.instance.Applications.loaded) {
        return;
      }
      (async () => {
        setApp(await props.instance.Applications.getById(props.record.id));
      })();
    }, [props.instance.Applications.loaded, props.instance.Applications.loading]);

    if (!app) {
      return <Spinner />;
    }
    return <Secrets application={app} />;
  })
);

AgentSecrets.suites = (record, userIsAdmin) =>
  userIsAdmin && record.app_1 && record.app_1.applicationType === 'std::Hosts:1';

export default AgentSecrets;
