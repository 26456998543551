import { types } from 'mobx-state-tree';

const Theme = types
  .model('Theme', {
    dark: false,
    pinnedSidebar: false,
  })
  .actions((self) => ({
    setDark(isDark) {
      // NOTE(andreykurilin): here we can try to retrieve color dependent
      //  variables from theme.
      if (isDark !== undefined) {
        self.dark = isDark;
      } else {
        self.dark = true;
      }
    },
    togglePinnedSidebar() {
      self.pinnedSidebar = !self.pinnedSidebar;
      localStorage.setItem('pinnedSidebar', self.pinnedSidebar);
    },

    afterCreate: () => {
      self.pinnedSidebar = localStorage.getItem('pinnedSidebar') !== 'false';
    },
  }));

const Settings = types.model('Settings', {
  theme: types.optional(Theme, () => Theme.create()),
});

export default Settings;
