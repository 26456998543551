import React, { useEffect, useState } from 'react';

import { inject, observer } from 'mobx-react';

import FormItem from 'components/forms/FormItem';
import Input from 'components/forms/Input';
import ReadOnlyInput from 'components/forms/Input/ReadOnlyInput';
import InputS from 'components/forms/Input/InputS';
import Switcher from 'components/forms/Switcher';
import { Spinner } from 'components/Loader';
import { UserRoles } from 'Constants';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';

const domain_validator = (value) => {
  if (!value.match('^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$')) {
    return 'Domain is not valid.';
  }
};

const NewWildcardDomainInputField = inject('org')(
  observer((props) => {
    const [inputStore] = useState(InputS.create({}));

    useEffect(() => {
      inputStore.addInputValidator(domain_validator);
      inputStore.addInputValidator((value) => {
        if (props.existingDomains && props.existingDomains.includes(value)) {
          return 'Domain is already registered.';
        }
      });
    }, []);

    const onCreate = () => {
      if (!inputStore.value) {
        inputStore.setError('Domain is empty.');
      } else if (inputStore.isDone()) {
        props.org.Settings.access.addWildcardDomain(
          inputStore.value,
          () => {},
          (response, errors) => inputStore.setError(errors.join(' '))
        );
      }
    };

    return (
      <Input store={inputStore}>
        <button
          className="btn-only-icon minus"
          type="button"
          onClick={() => {
            inputStore.setValue('');
            inputStore.setError(null);
          }}
        />
        <button
          key="add"
          className="btn-only-icon plus"
          type="button"
          onClick={onCreate}
          disabled={!inputStore.isDone()}
        />
      </Input>
    );
  })
);

const OAuth2WildcardDomainsBox = inject('org')(
  observer((props) => (
    <div className="form-box">
      <FormItem
        label="OAuth2 wildcard domains"
        description="Allow to self-create user account for users with email addresses from the specified domain(s)."
      />
      {props.org.Settings.access.wildcardDomains.map((value, index) => (
        <ReadOnlyInput key={`oauth2-wildcard-domain-${value}-${index}`} value={value}>
          <button
            className="btn-only-icon minus"
            type="button"
            onClick={() => props.org.Settings.access.deleteWildcardDomain(index)}
          />
        </ReadOnlyInput>
      ))}
      <NewWildcardDomainInputField
        key={
          /* make key depend on the length of the domain, so onCreate event will clean active input field */
          `oauth2-wildcard-domain-new-${props.org.Settings.access.wildcardDomains.length}`
        }
        existingDomains={props.org.Settings.access.wildcardDomains}
      />
    </div>
  ))
);

const AccessSettings = inject('org')(
  observer((props) => {
    const settings = props.org.Settings;

    const [wildcardInput] = useState(
      InputS.create({
        description: 'User creation is allowed to users with email addresses from the specified domain',
        formItemClassName: 'domain',
        value: settings.access.wildcardDomains ? settings.access.wildcardDomains[0] : undefined,
        label: 'Domain',
      })
    );

    useEffect(() => {
      wildcardInput.addInputValidator(domain_validator);
      wildcardInput.registerOnChangeHandler((value) => {
        if (wildcardInput.isDone()) {
          settings.access.setWildcardDomains(value);
        }
      });
    }, []);

    if (!settings.loaded) {
      return <Spinner />;
    }

    return (
      <SidedPanel>
        <LeftPanel>
          <h3>Access</h3>
          <form>
            <div className="form-box">
              <Switcher
                key="oauth2-enabled"
                itemID="oauth2-enabled"
                label="Authentication via OAuth2"
                checked={settings.access.OAuth2Enabled}
                onChange={settings.access.toggleOAuth2Enabled}
              />
            </div>
            <div className="form-box">
              <Switcher
                key="oauth2-only-domain-box"
                itemID="oauth2-only-domain-box"
                label="OAuth2 Only auth"
                description="Restrict authentication via email&password flow."
                checked={settings.access.OAuth2Only}
                onChange={settings.access.toggleOAuth2Only}
                disabled={!settings.access.OAuth2Enabled}
              />
            </div>
            <OAuth2WildcardDomainsBox />
            <div className="form-box">
              <Switcher
                key="oauth2-default-user-role"
                itemID="oauth2-default-user-role"
                label="Assign Admin role to new OAuth2 user."
                description="Whether to assign admin to an unregistered user that logged in via OAuth2 wildcard domain option."
                checked={UserRoles.ADMIN === settings.access.wildcardNewUserRole}
                disabled={!settings.access.OAuth2Enabled}
                onChange={() =>
                  settings.access.setWildcardNewUserRole(
                    UserRoles.ADMIN === settings.access.wildcardNewUserRole ? UserRoles.USER : UserRoles.ADMIN
                  )
                }
              />
            </div>
            <div className="form-box">
              <Input store={wildcardInput} />
              <div className="item-container">
                <button className="btn btn-primary" type="button" onClick={settings.access.saveWildcardDomains}>
                  Save domain
                </button>
              </div>
            </div>
          </form>
        </LeftPanel>
      </SidedPanel>
    );
  })
);

@inject('org')
@observer
export default class extends React.Component {
  componentDidMount() {
    if (!this.props.org.Settings.loading) {
      this.props.org.Settings.fetch();
    }
  }

  render() {
    return <AccessSettings />;
  }
}
