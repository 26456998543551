import { types } from 'mobx-state-tree';
import requests from 'lib/requests';
import { AuthenticationMixin } from 'pages/Auth/LoginS';
var OAuth2CallbackForm = AuthenticationMixin.named('OAuth2CallbackForm')
    .props({
    provider: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    notRegisteredYet: false,
    redirectUrl: '/',
    completed: false,
})
    .actions(function (self) { return ({
    markAsNotRegistered: function () {
        self.notRegisteredYet = true;
    },
    login: function () {
        // @ts-ignore: ts cannot determine our requests lib properties correctly. should be fixes by reworking requests
        requests.GET({
            url: "/m/api/v1/auth/oauth2/".concat(self.provider, "/callback?code=").concat(self.code),
            onFailure: self.processError,
            onSuccess: function (response, responseBody) {
                self.onSuccessLogin(responseBody.data.token);
            },
            onFinish: function () {
                self.setProcessing(false);
            },
        });
    },
}); });
export default OAuth2CallbackForm;
