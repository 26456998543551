import { types, getParentOfType } from 'mobx-state-tree';
import Store from 'stores/Store';

const ApplicationType = types
  .model('ApplicationType', {
    allowed_actions: types.array(types.string),
    description: types.string,
    organization: types.string,
    name: types.string,
    version: types.integer,
    status: types.string,
    visibility: types.string,
  })
  .views((self) => ({
    get fullName() {
      return `${self.organization}::${self.name}:${self.version}`;
    },
    get pictureURL() {
      return getParentOfType(self, ApplicationTypes).getPicture(self.fullName);
    },
  }));

const ApplicationTypes = types
  .model('ApplicationTypes', {
    items: types.maybeNull(types.array(ApplicationType)),
    loading: false,
    loaded: false,
  })
  .actions((self) => ({
    pushItems(data) {
      self.loading = false;
      self.items = data.map((at) => ApplicationType.create(at));
    },
    markLoaded() {
      self.loading = false;
      self.loaded = true;
    },
    fetch() {
      self.loading = true;
      Store.TransportLayer.get({
        url: '/m/api/v1/application-types',
        onSuccess: (response, response_data) => {
          self.pushItems(response_data.data);
          self.markLoaded();
        },
      });
    },
  }))
  .views(() => ({
    getPicture(fullName) {
      const token = Store.Profile.token;
      return `/m/api/v1/application-types/${fullName}/picture.svg?token=${token}`;
    },
  }));

export default ApplicationTypes;
