import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Input from 'components/forms/Input';
import Table, { TableRow, TableCell } from 'components/Table';
import ModelsPageFilters from 'pages/Api/Models/ModelsListS';
import { Spinner } from 'components/Loader';
var ModelsFilters = observer(function (_a) {
    var store = _a.store;
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    useEffect(function () {
        store.setNameFilter(searchParams.get('name'));
        store.setGroupFilter(searchParams.get('group'));
    }, [searchParams]);
    useEffect(function () {
        setSearchParams(store.queryParams);
    }, [store.queryParams]);
    return (React.createElement("div", { className: "filters-box" },
        React.createElement("form", { key: "group-selector" },
            React.createElement("div", { className: "form-box" },
                React.createElement(Input, { store: store.groupFilter }))),
        React.createElement("form", { className: "form-box" },
            React.createElement(Input, { store: store.nameFilter }))));
});
var ModelRow = function (_a) {
    var model = _a.model;
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, null,
            React.createElement("div", { className: "entity-type entity-icon", style: { background: "url(".concat(model.pictureUrl, ")") } }),
            React.createElement(Link, { to: "/catalog/models/".concat(model.fullName) }, model.fullName)),
        React.createElement(TableCell, null, model.inherits.join(', ')),
        React.createElement(TableCell, null, model.definition.obj.status)));
};
export default inject('store')(observer(function (_a) {
    var store = _a.store;
    var filtersStore = useState(ModelsPageFilters.create({}))[0];
    useEffect(function () {
        if (!store.Models.loaded && !store.Models.loading) {
            store.Models.fetch();
        }
    }, []);
    useEffect(function () {
        if (store.Models.loaded) {
            filtersStore.linkModelsStore(store.Models);
        }
    }, [store.Models.loaded]);
    if (!store.Models.loaded) {
        return React.createElement(Spinner, null);
    }
    var tableHeaders = [
        { title: 'Name', value: 'fullName' },
        { title: 'Inherits', value: 'inherits' },
        { value: 'status' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModelsFilters, { store: filtersStore }),
        React.createElement(Table, { headers: tableHeaders, onSortChange: filtersStore.sortBy }, filtersStore.sortedItems.map(function (m) { return (React.createElement(ModelRow, { model: m, key: m.fullName })); }))));
}));
