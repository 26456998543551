import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import {
  ColumnModel,
  ColumnRecordLink,
  ColumnReferences,
  ColumnTimestamp,
  VerTableStore,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';

const HostActivity = inject(
  'store',
  'profile',
  'instance'
)(
  observer((props) => {
    const query =
      "inherits('std::types/Auditable:1') " +
      "AND isNotSet('std::types/Versionable:1.deletedAt') " +
      `AND (std::types/Auditable:1.what == '${props.what}' ` +
      ` OR std::types/Auditable:1.relatedTo CONTAINS [${props.relatedTo.map((el) => `'${el}'`).join(', ')}])`;

    console.log(query);
    console.log(props.relatedTo);

    const [store] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 100,
        paginationRowsPerPageOptions: [10, 25, 50, 100, 250, 500],
        selectable: false,
        query: query,
        columns: [
          ColumnModel.create(),
          ColumnRecordLink.create({ name: 'Activity', key: 'std::types/Root:1.id', opts: { grow: 2 } }),
          ColumnReferences.create({ name: 'Related To', keys: ['std::types/Auditable:1.relatedTo'] }),
          ColumnReferences.create({ name: 'Agent', keys: ['std::types/Auditable:1.executor'] }),
          ColumnTimestamp.create({
            name: 'Updated At',
            key: 'std::types/Versionable:1.updatedAt',
          }),
        ],
      })
    );

    useEffect(() => {
      store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    return <Table store={store} />;
  })
);

const HostOrContainerOverviewTab = observer((props) => {
  const relatedTo = [props.record.id];

  if (props.record.data.has('std::host/Container:1')) {
    relatedTo.push(props.record.data['std::host/Container:1'].host);
  }

  return <HostActivity what={props.record.id} relatedTo={relatedTo} />;
});

HostOrContainerOverviewTab.suites = (record) =>
  record.data.has('std::host/Host:1') || record.data.has('std::host/Container:1');

export default HostOrContainerOverviewTab;
