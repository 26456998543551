import React from 'react';
import { observer, inject } from 'mobx-react';

import moment from 'moment';

import { parseDiff, Diff, Hunk } from 'react-diff-view';
import { diffLines, formatLines } from 'unidiff';
import JSONStableStringify from 'json-stable-stringify';

import HeatMapCalendar from 'components/charts/HeatMapCalendar';
import { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';
import Icon from 'components/Icon';
import { Spinner } from 'components/Loader';
import { InstanceLink } from 'components/Nav';
import Hint from 'components/Hint';

@inject('instance')
@observer
class ArrayWithReferences extends React.Component {
  constructor(props) {
    super(props);
    // use a special flag here to make componentDidMount be executed before
    // a first call of render, so we can use mget for records.
    this.state = { loaded: false };
  }

  componentDidMount() {
    if (this.props.items) {
      this.props.instance.InventoryRecords.getMultiByIDs(this.props.items, this.toggleLoaded);
    }
  }

  toggleLoaded = () => {
    this.setState({ loaded: true });
  };

  render() {
    if (!this.state.loaded || !this.props.items) {
      return JSONStableStringify(this.props.items || []);
    }

    const children = [];
    this.props.items.forEach((recordId, index) => {
      const record = this.props.instance.InventoryRecords.getById(recordId);

      children.push(
        <InstanceLink key={recordId} to={`/records/${recordId}`}>
          {/* this might happen in case of sys operator records, someday we will fix it */}
          {(record.inventory_1 && record.inventory_1.displayName) || recordId}
        </InstanceLink>
      );
      if (index !== this.props.items.length - 1) {
        children.push(', ');
      }
    });

    // TODO SOmething is of here, why not reutringing
    return <>[{children}]</>;
  }
}

class JSONDiff extends React.Component {
  processValue(value) {
    if (!value) {
      return '{}';
    }
    return JSONStableStringify(JSON.parse(value), { space: '  ' });
  }

  render() {
    const oldValue = this.processValue(this.props.oldValue);
    const newValue = this.processValue(this.props.newValue);

    const diffText = formatLines(diffLines(oldValue, newValue), { context: 3 });
    const diffs = parseDiff(diffText, { nearbySequences: 'zip' });
    const diff = diffs[0];
    return (
      <div className="diffContainer">
        <Diff viewType="split" diffType={diff.type} hunks={diff.hunks}>
          {(hunks) => hunks.map((hunk) => <Hunk key={hunk.content} hunk={hunk} />)}
        </Diff>
      </div>
    );
  }
}

const FieldDiff = inject('store')(
  observer((props) => {
    const [modelName, fieldName] = props.change.fieldName.split('.');
    const model = props.store.Models.getByIdentifier(modelName);

    let diff;
    let oldValue = props.change.old;
    let newValue = props.change.new;

    if (props.store.Models.loaded && model) {
      let fieldSpec = model.definition.obj.fields[fieldName];
      if (fieldSpec === undefined) {
        // our models are not synced with data
        fieldSpec = {};
      }

      if (fieldSpec.type === 'timestamp') {
        oldValue = oldValue ? moment.unix(oldValue).format() : '';
        newValue = newValue ? moment.unix(newValue).format() : '';
      } else if (fieldSpec.contentType === 'json') {
        diff = <JSONDiff oldValue={oldValue} newValue={newValue} />;
      } else if (
        fieldSpec.type === 'array' &&
        (fieldSpec.items === 'reference' ||
          (typeof fieldSpec.items === 'object' && fieldSpec.items.type === 'reference'))
      ) {
        oldValue = <ArrayWithReferences items={oldValue} key="old" />;
        newValue = <ArrayWithReferences items={newValue} key="new" />;
      } else {
        oldValue = JSON.stringify(oldValue);
        newValue = JSON.stringify(newValue);
      }
    }

    if (diff === undefined) {
      diff = (
        <>
          <span>{oldValue}</span> &rarr; <span>{newValue}</span>
        </>
      );
    }
    return (
      <div className="history-item">
        <div className="history-object">
          {modelName}.<b>{fieldName}</b>
        </div>
        <div className="history-changes">{diff}</div>
      </div>
    );
  })
);

const SnapshotPopover = (props) => (
  <div className="actions">
    <Hint className="hint-left">
      <Hint.Trigger>
        <Icon className="document-details" />
      </Hint.Trigger>
      <Hint.Window>
        <SimpleTextViewer syntax={Syntaxes.json} value={JSON.stringify(props.data, null, 4)} />
      </Hint.Window>
    </Hint>
  </div>
);

const Report = (props) => {
  let badgeValue;
  let badgeClassName;

  if (props.report.allChanges.length === 0) {
    badgeValue = 'new';
    badgeClassName = 'bg-success';
  } else if (props.report.snapshot['std::types/Versionable:1'].deletedAt) {
    badgeValue = 'del';
    badgeClassName = 'bg-danger';
  } else {
    badgeValue = props.report.changes.length;
  }

  const initEvent = props.report.allChanges.length === 0;
  const isRootRecord = props.rootRecord.id === props.report.recordId;

  return (
    <div className="history-item-group">
      <div className="history-item">
        <div className="changes-counter">
          <span className={badgeClassName}>{badgeValue}</span>
        </div>
        <div className="history-message">
          <span>
            {isRootRecord && props.report.displayName}
            {!isRootRecord && (
              <InstanceLink to={`/records/${props.report.recordId}`}>{props.report.displayName}</InstanceLink>
            )}
          </span>
          <span>{props.report.modelName}</span>
        </div>
        <div className="history-version">Ver. {props.report.version}</div>
        <div className="history-date">{props.report.changedAt}</div>
        <SnapshotPopover data={props.report.snapshot} />
      </div>
      {initEvent && (
        <div className="history-item">
          <div className="history-object">Record is created at ITLook system.</div>
        </div>
      )}
      {!initEvent && props.report.changes.map((change) => <FieldDiff key={change.fieldName} change={change} />)}
    </div>
  );
};

const ReportsGroup = (props) => {
  let formattedDate;
  if (props.diffFromNow < 60) {
    formattedDate = props.reports[0].mChangedAt.format('MMM D YYYY, HH:mm');
  } else {
    formattedDate = props.reports[props.reports.length - 1].mChangedAt.format('YYYY-MM-DD');
  }

  return (
    <div className="group">
      <div className="date-item">
        <div className="date">
          <span>{formattedDate}</span>
          <span>{props.title}</span>
        </div>
      </div>
      {props.reports.map((report) => (
        <Report report={report} key={`${report.recordId}-${report.version}`} rootRecord={props.record} />
      ))}
    </div>
  );
};

const YearsSelector = observer((props) => {
  if (props.history.reports.length === 0) {
    return <div className="just-a-placeholder" />;
  }
  const years = Array.from(new Set(props.history.reports.map((r) => r.mChangedAt.year()))).sort((a, b) => b - a);
  const selectYear = (year) => {
    if (year !== props.history.selectedYear) {
      props.history.setPeriod(new Date(year, 0).getTime());
    }
  };

  return (
    <div className="years-select-container">
      {years.map((year) => (
        <li key={year} className={year === props.history.selectedYear ? 'active' : ''} onClick={() => selectYear(year)}>
          {year}
        </li>
      ))}
    </div>
  );
});

const CalendarChart = observer((props) => {
  if (!props.record.history.Stats.loaded || props.record.history.Stats.loading) {
    return <Spinner />;
  }

  return (
    <HeatMapCalendar
      onClick={(dateObj) => props.record.history.setBeforeDate(dateObj.unix())}
      data={props.record.history.Stats.data}
    />
  );
});

const ReportsContainer = observer((props) => {
  if (!props.record.history.loaded) {
    return null;
  }

  const now = moment();

  const groups = [];
  let currentGroup;

  props.record.history.reports.forEach((report) => {
    const timeDiffFromNow = now.diff(report.mChangedAt, 'minutes');

    let groupId;
    if (timeDiffFromNow < 10) {
      groupId = 'during latest 10 minutes';
    } else if (timeDiffFromNow < 60) {
      groupId = 'during latest hour';
    } else if (timeDiffFromNow < 60 * 24) {
      groupId = 'One day ago';
    } else {
      groupId = report.mChangedAt.format('Do MMMM');
    }

    if (currentGroup === undefined || currentGroup.title !== groupId) {
      if (currentGroup !== undefined) {
        groups.push(currentGroup);
      }
      currentGroup = { title: groupId, diffFromNow: timeDiffFromNow, reports: [] };
    }
    currentGroup.reports.push(report);
  });

  if (currentGroup !== undefined) {
    groups.push(currentGroup);
  }

  return (
    <div className="history-item-container">
      {groups.map((group) => (
        <ReportsGroup
          record={props.record}
          reports={group.reports}
          title={group.title}
          diffFromNow={group.diffFromNow}
          key={group.title}
        />
      ))}
    </div>
  );
});

@inject('store')
@observer
export default class extends React.Component {
  componentDidMount() {
    if (!this.props.record.history.loading) {
      this.props.record.history.fetch();
    }
    if (!this.props.store.Models.loaded && !this.props.store.Models.loading) {
      this.props.store.Models.fetch();
    }
  }

  componentWillUnmount() {
    this.props.record.history.resetModelFilter();
    this.props.record.history.resetSelectedDate();
  }

  loadMore = () => {
    this.props.record.history.fetch(this.props.record.history.nextToLoad);
  };

  render() {
    // use an array instead of an object here to control the order explicitly
    // const filtersBox = [
    //   [GlobalModelFilters.all, 'Show all'],
    //   [GlobalModelFilters.target, 'Show only target'],
    //   ...this.props.record.history.models.map((m) => {
    //     return [m, `Show records of ${m}`];
    //   }),
    // ];

    return (
      <>
        <div className="filters-box">
          <YearsSelector history={this.props.record.history} />

          <CalendarChart key={this.props.record.history.Stats.beforeDate} record={this.props.record} />
          <div key="empty-div-that-should-move-calendar-to-the-center" />

          {/* We will show history only of this record for now
          <form className="form-box">
            <DropdownSelector
              options={filtersBox}
              selected={this.props.record.history.modelFilter}
              onSelect={this.props.record.history.setModelFilter}
            />
          </form> */}
        </div>
        <div className="history-container">
          <ReportsContainer record={this.props.record} />
        </div>
        {this.props.record.history.loading && <Spinner />}
        {!this.props.record.history.loading && this.props.record.history.nextToLoad && (
          <div className="filters-box push-center">
            <div className="btn btn-default" onClick={this.loadMore}>
              Load more
            </div>
          </div>
        )}
      </>
    );
  }
}
