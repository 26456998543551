import React from 'react';
import { observer } from 'mobx-react';

import * as ContentHeader from 'components/ContentHeader';
import { RecordLink } from 'components/InventoryLinks';
import { AllowedStatuses, StatusToCls } from 'components/charts/StatusChart';
import { Description } from 'pages/Instances/RecordView/Header/CommonItems';

const SessionSpecificToCommonStatesMap = {
  connecting: AllowedStatuses.Unknown,
  open: AllowedStatuses.Ok,
  closed: AllowedStatuses.Deleted,
  'failed-to-connect': AllowedStatuses.Critical,
};

export default observer((props) => {
  const unifiedState = SessionSpecificToCommonStatesMap[props.record.session_1.state];

  return (
    <ContentHeader.Container>
      <ContentHeader.Column>
        <ContentHeader.Item>
          <span>Status:</span>
          <b className={`text-${StatusToCls[unifiedState]}`}>{props.record.session_1.state}</b>
        </ContentHeader.Item>
      </ContentHeader.Column>
      <ContentHeader.Column>
        <Description description={props.record.session_1.cause} />
      </ContentHeader.Column>
      <ContentHeader.Column>
        <ContentHeader.Item>
          <span>Traffic (in / out):</span> {props.record.session_1.humanFriendlyTrafficIn} /{' '}
          {props.record.session_1.humanFriendlyTrafficOut}
        </ContentHeader.Item>
      </ContentHeader.Column>
      <ContentHeader.Column>
        <ContentHeader.Item>
          <span>Target:</span> <RecordLink recordId={props.record.session_1.target} className="btn-text" />
        </ContentHeader.Item>
      </ContentHeader.Column>
      <ContentHeader.Column>
        <ContentHeader.Item>
          <span>Started By:</span> <RecordLink recordId={props.record.session_1.user} className="btn-text" />
        </ContentHeader.Item>
      </ContentHeader.Column>
    </ContentHeader.Container>
  );
});
