import React from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { withLocation } from 'lib/router-helper';
import { sortObjects } from 'lib/sort-utils';

import { UserLink } from 'components/InventoryLinks';
import Icon from 'components/Icon';
import { Spinner } from 'components/Loader';
import { ConfirmationAboutDeleteWindow } from 'components/ModalWindow';
import { InstanceLink } from 'components/Nav';
import { ContainerBox, PageHeader } from 'components/Page';
import Table, { TableRow, TableCell } from 'components/Table';

import ScriptsStore from 'pages/Instances/Scripts/ScriptsListS';

const defaultIcon = 'file';
const icons = {
  folder: 'folder',
  bash: defaultIcon,
  parent: 'folder',
};

const Row = inject('instance')(
  observer((props) => {
    const navigate = useNavigate();

    let iconClass;
    if (props.hasOwnProperty('iconClass')) {
      iconClass = props.iconClass;
    } else {
      iconClass = icons[props.type] || defaultIcon;
    }
    let name;
    if (props.link && props.name) {
      // this link is redundant cause the whole raw is clickable, but showing
      //  cursor on the name makes people happy
      name = <InstanceLink to={props.link}>{props.name}</InstanceLink>;
    } else {
      name = props.name;
    }

    const onClick = () => {
      if (props.link) {
        navigate(`/i/${props.instance.name}${props.link}`);
      }
    };

    const handleOnDeleteClick = (e) => {
      e.stopPropagation();
      props.onDeleteClick();
    };

    return (
      <TableRow onClick={onClick} title={props.title}>
        <TableCell>
          <Icon className={iconClass} />
        </TableCell>
        <TableCell key="name">{name}</TableCell>
        <TableCell key="type">
          {/*
            NOTE(andreykurilin): this column, imho, can be removed as soon
            as Bogdan create icons for different types + icon tooltip with
            language info.
         */}
          {props.scriptType}
        </TableCell>
        <TableCell key="owner">{props.author && <UserLink recordId={props.author} />}</TableCell>
        <TableCell key="updatedAt">{props.updatedAt && props.updatedAt.format('MMM D YYYY, HH:mm')}</TableCell>
        {props.showDeleteColumn && (
          <TableCell key="delete">
            <Icon onClick={handleOnDeleteClick} className="delete" />
          </TableCell>
        )}
        {props.showEmptyDeleteColumn && <TableCell />}
      </TableRow>
    );
  })
);

const ScriptRow = observer((props) => (
  <Row
    key={props.script.recordID}
    recordID={props.script.recordID}
    name={props.script.name}
    type={props.script.syntax}
    author={props.script.author}
    updatedAt={props.script.updatedAt}
    showDeleteColumn={props.showDeleteColumn && props.script.canBeDelete()}
    onDeleteClick={() => props.pageStore.setScriptToDelete(props.script)}
    scriptType={props.script.scriptType}
    link={`/records/${props.script.recordID}`}
  />
));

@inject('instance', 'profile')
@observer
class ScriptsTable extends React.Component {
  tableHeaders = [
    { itemKey: 'itemType', empty: true },
    { value: 'name' },
    { value: 'type' },
    { value: 'author', title: 'Author' },
    { value: 'updatedAt', title: 'Updated at' },
  ];

  deleteHeader = { empty: true, itemKey: 'delete' };

  defaultSortFieldsOrder = ['name', 'updatedAt', 'language'];

  parentRowActiveMsg = 'Navigate to parent directory';

  parentRowInActiveMsg = 'You are on the root level';

  constructor(props) {
    super(props);
    this.pageStore = this.props.pageStore;
  }

  render() {
    const tree = this.pageStore.tree.get(this.pageStore.currentDirPath);
    if (tree === undefined) {
      return <Spinner />;
    }
    if (tree.dirs.length === 0 && tree.scripts.length === 0) {
      if (this.pageStore.loading) {
        // there are no cached scripts yet, but the new loading is not finished
        return <Spinner />;
      }
      return 'There are no scripts.';
    }

    const dirs = tree.dirs.slice().sort((dirA, dirB) => {
      let res = dirA.localeCompare(dirB);
      if (this.pageStore.sortByField === 'name' && this.pageStore.reverseOrder) {
        res = -res;
      }
      return res;
    });

    const scripts = tree.scripts
      .slice()
      .sort((scriptA, scriptB) =>
        sortObjects(
          scriptA,
          scriptB,
          this.pageStore.sortByField,
          this.defaultSortFieldsOrder,
          this.pageStore.reverseOrder
        )
      );

    // TODO(andreykurilin): check here whether any of script is owned by
    //    current user to decide whether we can add delete button

    const showDeleteColumn = this.props.instance.amIAdmin;
    let headers;
    if (showDeleteColumn) {
      headers = [...this.tableHeaders, this.deleteHeader];
    } else {
      headers = this.tableHeaders;
    }

    const getDirLink = (dirName) => `/scripts?dir=${dirName}`;

    return (
      <Table
        className="table-files-list"
        headers={headers}
        onSortChange={this.pageStore.sortBy}
        selected={this.pageStore.sortByField}
      >
        <Row
          name=""
          iconClass="levelup"
          title={this.pageStore.isRootDir() ? this.parentRowInActiveMsg : this.parentRowActiveMsg}
          link={!this.pageStore.isRootDir() && getDirLink(this.pageStore.parentDir)}
          showEmptyDeleteColumn={showDeleteColumn}
        />
        {dirs.map((dirName) => (
          <Row
            key={dirName}
            iconClass="folder"
            name={dirName}
            link={getDirLink(this.pageStore.absPath(dirName))}
            showEmptyDeleteColumn={showDeleteColumn}
          />
        ))}
        {scripts.map((script) => (
          <ScriptRow
            key={script.recordID}
            script={script}
            showDeleteColumn={showDeleteColumn}
            pageStore={this.pageStore}
          />
        ))}
      </Table>
    );
  }
}

@withLocation
@inject('instance')
@observer
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.pageStore = ScriptsStore.create({});
    this.pageStore.linkInstanceStore(this.props.instance);
  }

  componentDidMount() {
    if (!this.props.instance.Applications.loaded && !this.props.instance.Applications.loading) {
      this.props.instance.Applications.fetch();
    }
    this.proceedUrlQuery();
  }

  componentDidUpdate() {
    // refactor this to use new router and new components, to avoid 3 queries to backend
    this.proceedUrlQuery();
  }

  proceedUrlQuery = () => {
    const currentDir = new URLSearchParams(this.props.location.search).get('dir') || '/';
    this.pageStore.setCurrentDir(currentDir);
  };

  closeModalWindow = () => {
    this.pageStore.setScriptToDelete(null);
  };

  render() {
    let body = <Spinner />;
    let title = 'Scripts';
    let subTitles = [];

    if (this.props.instance.Applications.loaded) {
      body = (
        <>
          <ScriptsTable pageStore={this.pageStore} />
          {this.pageStore.scriptToDelete && (
            <ConfirmationAboutDeleteWindow
              onClose={this.closeModalWindow}
              onCancel={this.closeModalWindow}
              onDelete={this.pageStore.deleteScript}
            />
          )}
        </>
      );
      if (this.pageStore.currentDirPath !== '/') {
        title = <InstanceLink to="/scripts">Scripts</InstanceLink>;
        let curPosition = '/';
        subTitles = this.pageStore.currentDirPath
          .split('/')
          .filter((i) => i)
          .map((dirName) => {
            curPosition = `${curPosition}${dirName}/`;
            return <InstanceLink to={`/scripts?dir=${curPosition}`}>{dirName}</InstanceLink>;
          });
      }
    }

    return (
      <ContainerBox>
        <PageHeader title={title} documentTitle="Scripts" subTitle={subTitles}>
          <InstanceLink
            to={`/scripts/create?${new URLSearchParams({ rootDir: this.pageStore.currentDirPath }).toString()}`}
            className="btn btn-primary"
          >
            <Icon className="create-new-btn" />
            Create new
          </InstanceLink>
        </PageHeader>
        {body}
      </ContainerBox>
    );
  }
}
