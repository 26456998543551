import React, { useEffect, useState } from 'react';

import { PageHeader, ContainerBox } from 'components/Page';

import {
  VerTableStore,
  ColumnText,
  ColumnRecordLink,
  ColumnReferences,
  ColumnModel,
  ColumnTimestamp,
  ColumnInventoryRecordStatus,
  loadRecodsWithStatusStats,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';

import { observer, inject } from 'mobx-react';

export const ContainersTable = inject(
  'store',
  'instance'
)(
  observer((props) => {
    let query = "inherits('std::host/Container:1') AND std::types/Statusable:1.status!='archived'";
    if (props.host) {
      query += ` AND std::host/Container:1.host == '${props.host}'`;
    }

    const [store] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 25,
        paginationRowsPerPageOptions: [25, 50, 100],
        selectable: false,
        query: query,
        columns: [
          ColumnModel.create({}),
          ColumnInventoryRecordStatus.create({ name: 'Status', opts: { width: '100px' } }),
          ColumnRecordLink.create({
            name: 'Name',
            key: 'std::types/Root:1.id',
            sortKey: 'std::types/Inventory:1.displayName',
          }),
          ColumnText.create({ name: 'Image', key: 'std::host/Container:1.imageName' }),
          ColumnReferences.create({ name: 'Host', keys: ['std::host/Container:1.host'] }),
          ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
      })
    );

    useEffect(() => {
      loadRecodsWithStatusStats(store);
      store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    return <Table store={store} />;
  })
);

export default () => (
  <ContainerBox>
    <PageHeader title="Containers" />
    <ContainersTable />
  </ContainerBox>
);
