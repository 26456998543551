import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as queryString from 'lib/query-string';
import Icon from 'components/Icon';
import Input from 'components/forms/Input';
import UserFirstLogin from 'pages/Auth/components/UserFirstLogin';
import PageLayout from 'pages/Auth/components/PageLayout';
import LoginFormStore from 'pages/Auth/LoginS';
import { LoginActions } from 'Constants';
var OAuthProvider = inject('notifications')(function (_a) {
    var link = _a.link, name = _a.name, notifications = _a.notifications;
    var onClick = function () {
        if (process.env.DEMO_MODE) {
            notifications.warn("Sorry ".concat(name, " OAuth2 provider is disabled in Demo mode."));
        }
        else {
            window.location.href = link;
        }
    };
    return (React.createElement("button", { type: "button", title: "Use oauth ".concat(name), className: "btn btn-default", onClick: onClick },
        React.createElement(Icon, { className: name.toLowerCase() }),
        name));
});
var OAuthLinks = function (_a) {
    var redirect_url = _a.redirect_url;
    var oAuth2Query = '';
    if (redirect_url) {
        var encodedRedirectUrl = encodeURIComponent(redirect_url);
        oAuth2Query = "?start_url=".concat(encodedRedirectUrl);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("hr", null),
        React.createElement("div", { className: "login-services-container" },
            React.createElement(OAuthProvider, { key: "google", name: "Google", link: "".concat(process.env.OAUTH2_GOOGLE_URL).concat(oAuth2Query) }),
            React.createElement(OAuthProvider, { key: "github", name: "GitHub", link: "".concat(process.env.OAUTH2_GITHUB_URL).concat(oAuth2Query) }))));
};
var ResetPassword = function (_a) {
    var redirect_url = _a.redirect_url;
    var resetPasswordQuery = '';
    if (redirect_url) {
        var encodedRedirectUrl = encodeURIComponent(redirect_url);
        resetPasswordQuery = "?redirect_url=".concat(encodedRedirectUrl);
    }
    return (React.createElement("div", { className: "login-link-container" },
        React.createElement(Link, { to: "/reset-password".concat(resetPasswordQuery) }, "Forgot password?")));
};
var CredentialsForm = observer(function (_a) {
    var store = _a.store;
    if (store.firstLogin || store.mfa) {
        return null;
    }
    return (React.createElement(PageLayout, { title: "Sign in" },
        React.createElement("form", { className: "form-box", onSubmit: function (e) {
                e.preventDefault();
            } },
            React.createElement(Input, { key: "email", store: store.email, autoFocus: true }),
            React.createElement(Input, { key: "password", store: store.password, onKeyDown: function (e) { return e.code === 'Enter' && store.login(); } }),
            React.createElement("div", { className: "message-placeholder text-danger" }, store.error),
            React.createElement("button", { type: "button", className: "btn-primary btn-block", onClick: store.login, disabled: !store.filled }, "Sign In"),
            !process.env.DISABLE_NOT_WORKING && React.createElement(OAuthLinks, { redirect_url: store.redirect_url }),
            !process.env.DISABLE_NOT_WORKING && React.createElement(ResetPassword, { redirect_url: store.redirect_url }))));
});
var TOTPCode = observer(function (_a) {
    var store = _a.store;
    if (!store.mfa) {
        return null;
    }
    return (React.createElement(PageLayout, { title: "Sign in" },
        React.createElement("form", { className: "form-box", onSubmit: function (e) {
                e.preventDefault();
            } },
            React.createElement(Input, { store: store.totpCode, autoFocus: true }),
            React.createElement("button", { type: "button", className: "btn-primary btn-block", onClick: store.submitTotpCode, disabled: store.processing || !store.totpCode.isDone() }, "Submit"))));
});
export default inject('store')(observer(function (_a) {
    var store = _a.store;
    var form = useState(LoginFormStore.create({}))[0];
    var navigate = useNavigate();
    var location = useLocation();
    form.registerURLPathUpdater(navigate);
    form.registerSetToken(store.Profile.setToken);
    useEffect(function () {
        var query = queryString.parse(location.search);
        form.setRedirectURL(query.redirect_url);
        if (query.action && LoginActions.hasOwnProperty(query.action)) {
            var action = LoginActions[query.action];
            store.Notifications.notify(action.msg, action.notificationType);
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(UserFirstLogin, { store: form }),
        React.createElement(CredentialsForm, { store: form }),
        React.createElement(TOTPCode, { store: form })));
}));
