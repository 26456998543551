import React from 'react';

import { Link } from 'react-router-dom';

import Notifications from 'components/Notifications';

export default (props) => (
  <>
    <div className="grid login">
      <Notifications />
      <div className="content">
        <div className="container-login">
          <div className="login-box">
            <div className="logo" />
            <div className="title">
              <span>{props.title}</span>
              {props.createAccountLink !== false && !process.env.DEMO_MODE && !process.env.DISABLE_NOT_WORKING && (
                <span>
                  <Link to="/signup">Create an account</Link>
                </span>
              )}
            </div>
            {props.children}
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-login">
          <div className="copyrights">2024 &copy; Made with &#10084; in Kharkiv</div>
        </div>
      </div>
    </div>
  </>
);
