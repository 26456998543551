// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
`, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontsUi": `"Inter",Helvetica,system-ui,sans-serif`,
	"fontsMono": `"Roboto Mono","Martian Mono","Menlo","Monaco","Consolas",monospace`,
	"headerbarHeight": `60px`,
	"sidebarWidth": `200px`,
	"rightbarWidth": `300px`,
	"blur": `6px`,
	"fontSize1": `calc(clamp(1.563rem, 0.495vw + 1.429rem, 1.875rem))`,
	"fontSize2": `calc(clamp(1.375rem, 0.297vw + 1.295rem, 1.563rem))`,
	"fontSize3": `calc(clamp(1.125rem, 0.198vw + 1.072rem, 1.25rem))`,
	"fontSize4": `calc(clamp(0.875rem, 0.396vw + 0.769rem, 1.125rem))`,
	"fontSize5": `calc(clamp(0.625rem, 0.396vw + 0.519rem, 0.875rem))`,
	"fontSize6": `calc(clamp(0.5rem, 0.396vw + 0.394rem, 0.75rem))`,
	"background": `#1E293B`,
	"content": `#334155`,
	"contentSub": `#475569`,
	"text": `#CBD5E1`,
	"textSub": `#94A3B8`,
	"lineColor": `#475569`,
	"gray000": `#050709`,
	"gray010": `#0A0D11`,
	"gray020": `#0F141A`,
	"gray030": `#141A22`,
	"gray040": `#1F2733`,
	"gray050": `#293444`,
	"gray060": `#334155`,
	"gray070": `#405066`,
	"gray080": `#4D5F78`,
	"gray090": `#5B6E89`,
	"gray100": `#617692`,
	"tint000": `#687D9B`,
	"tint010": `#6E85A3`,
	"tint020": `#758CAC`,
	"tint030": `#889CB7`,
	"tint040": `#9BACC3`,
	"tint050": `#AFBCCE`,
	"tint060": `#B8C4D4`,
	"tint070": `#C2CCDA`,
	"tint080": `#CBD4DF`,
	"tint090": `#D5DCE5`,
	"tint100": `#e3e6e9`,
	"tint120": `rgba(227,230,233,0.65098)`,
	"tint130": `rgba(227,230,233,0.219608)`,
	"black": `#000`,
	"glass": `rgba(51,65,85,0.8)`,
	"glassFF": `rgba(51,65,85,0.85)`,
	"darkGlass": `rgba(30,41,59,0.85)`,
	"white": `#fff`,
	"transparent": `rgba(255,255,255,0)`,
	"success": `#1a936f`,
	"danger": `#d05353`,
	"warning": `#eea243`,
	"unknown": `#b8bfc6`,
	"mediumRed": `#d05353`,
	"lightRed": `#e89696`,
	"mediumOrange": `#d08b53`,
	"lightOrange": `#e7bd93`,
	"mediumYellow": `#d0a553`,
	"lightYellow": `#e8cf96`,
	"mediumGreen": `#1a936f`,
	"lightGreen": `#bed1c5`,
	"brightGreen": `#f4f7f5`,
	"mediumBlue": `#598EF3`,
	"lightBlue": `#96cee8`,
	"brightBlue": `#f2f9fc`,
	"link": `#0099F7`,
	"linkHover": `#2baeff`,
	"linkActive": `#000`,
	"navbarBg": `#1E293B`,
	"navbarBgHover": `#334155`,
	"navbarText": `#94A3B8`,
	"navbarTextHover": `#CBD5E1`,
	"sidebarText": `#CBD5E1`,
	"btnAnimationTime": `.2s`,
	"btnBorderRadius": `3px`,
	"btnDefaultColor": `#475569`,
	"btnDefaultColorHover": `#516178`,
	"btnDefaultText": `rgba(203,213,225,0.8)`,
	"btnDefaultTextHover": `#cbd5e1`,
	"btnPrimaryColor": `#0099F7`,
	"btnPrimaryColorHover": `#0089de`,
	"btnPrimaryText": `rgba(255,255,255,0.8)`,
	"btnPrimaryText-hover": `#fff`,
	"btnSuccessColor": `#1a936f`,
	"btnSuccessColorHover": `#167d5f`,
	"btnSuccessText": `rgba(255,255,255,0.8)`,
	"btnSuccessTextHover": `#fff`,
	"btnWarningColor": `#eea243`,
	"btnWarningColorHover": `#ec962c`,
	"btnWarningText": `rgba(255,255,255,0.8)`,
	"btnWarningTextHover": `#fff`,
	"btnDangerColor": `#d05353`,
	"btnDangerColorHover": `#cb3f3f`,
	"btnDangerText": `rgba(255,255,255,0.8)`,
	"btnDangerTextHover": `#fff`,
	"formBorder": `#506685`,
	"formBorderHover": `#657ea3`,
	"formLabel": `#94A3B8`,
	"formPlaceholder": `#94A3B8`,
	"formPlaceholderDisabled": `#7589a4`,
	"formTextDisabled": `#7589a4`,
	"chartBlue": `#3E92CC`,
	"chartBlue1": `#357eb1`,
	"chartBlue2": `#2b6b97`,
	"chartBlue3": `#23587e`,
	"chartBread": `#d1b490`,
	"chartBread1": `#b59c7d`,
	"chartBread2": `#9b856a`,
	"chartBread3": `#816e57`,
	"chartBrown": `#A6633F`,
	"chartBrown1": `#905535`,
	"chartBrown2": `#7A472C`,
	"chartBrown3": `#653A23`,
	"chartGreen": `#4A7C59`,
	"chartGreen1": `#84b09c`,
	"chartGreen2": `#709685`,
	"chartGreen3": `#5c7d6e`,
	"chartMint": `#98CBB4`,
	"chartMint1": `#84b09c`,
	"chartMint2": `#709685`,
	"chartMint3": `#5c7d6e`,
	"chartOrange": `#ee7b30`,
	"chartOrange1": `#cf6a28`,
	"chartOrange2": `#b15a21`,
	"chartOrange3": `#934a1a`,
	"chartViolet": `#864db7`,
	"chartViolet1": `#7936ae`,
	"chartViolet2": `#682e97`,
	"chartViolet3": `#582580`,
	"chartPurple": `#BC5090`,
	"chartPurple1": `#a3447d`,
	"chartPurple2": `#8b396a`,
	"chartPurple3": `#732e57`,
	"chartRed": `#C14953`,
	"chartRed1": `#a73e47`,
	"chartRed2": `#8f343b`,
	"chartRed3": `#772a30`,
	"chartYellow": `#E4BC42`,
	"chartYellow1": `#C6A338`,
	"chartYellow2": `#A98B2F`,
	"chartYellow3": `#8D7325`,
	"chartOlive": `#498037`,
	"chartOlive1": `#4b723d`,
	"chartOlive2": `#3e6033`,
	"chartOlive3": `#334f29`,
	"chartGray": `#708080`,
	"chartGray1": `#607070`,
	"chartGray2": `#506060`,
	"chartGray3": `#405050`
};
export default ___CSS_LOADER_EXPORT___;
