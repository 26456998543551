export default (func, wait) => {
  let prevTimeout = null;

  const debouncedFunc = (...args) => {
    if (prevTimeout) {
      clearTimeout(prevTimeout);
    }
    prevTimeout = setTimeout(() => {
      func(...args);
    }, wait);
  };

  return debouncedFunc;
};
