import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { observer } from 'mobx-react';

import Popover from 'components/Popover';
import { DateFormat, TimeFormat } from 'Constants';

export const AllowedStatuses = {
  Ok: 'ok',
  Pending: 'Pending',
  Running: 'Running',
  Unknown: 'unknown',
  Deleted: 'deleted',
  Warning: 'warning',
  Critical: 'critical',
  DoesNotExistYet: 'null',
};

// the order is matter for displaying events grouped by statuses at a cigar
const AllowedStatusesList = [
  AllowedStatuses.Critical,
  AllowedStatuses.Warning,
  AllowedStatuses.Deleted,
  AllowedStatuses.Unknown,
  AllowedStatuses.Ok,
  AllowedStatuses.DoesNotExistYet,
];

// we do not describe an object here to avoid retyping statuses that allows us
// to ensure that if someday we will change statuses, it will be also handled
// here.

export const StatusToCls = Object.fromEntries([
  [AllowedStatuses.Ok, 'success'],
  [AllowedStatuses.Pending, 'unknown pulse'],
  [AllowedStatuses.Running, 'success pulse'],
  [AllowedStatuses.Unknown, 'unknown'],
  [AllowedStatuses.Deleted, 'unavailable'],
  [AllowedStatuses.Warning, 'warning'],
  [AllowedStatuses.Critical, 'danger'],
  [AllowedStatuses.DoesNotExistYet, 'not-exist'],
]);

export const JustADot = (props) => (
  <div className={`entity-status bg-${StatusToCls[props.status]}`} title={props.title} />
);

const RecordDoesNotExistYetExplanation = 'The record did not exist yet';

const defaultPopoverForJustACigar = (timestamp, stats, interval) => {
  const sortedStatuses = Object.entries(stats)
    .map(([key, value]) => [value, key])
    .sort()
    .reverse();
  const body = sortedStatuses.map(([rawValue, status]) => {
    const statusName = status === AllowedStatuses.DoesNotExistYet ? RecordDoesNotExistYetExplanation : status;
    // ensure that value is in range of 0.00 - 100.00
    const value = Math.round(rawValue * 100 * 100) / 100;
    // calculate period of time
    const duration = moment.duration(interval * rawValue);
    return (
      <div key={status}>
        <JustADot status={status} />
        <span className={`text-${StatusToCls[status]}`}>{statusName}</span>: {value}% ({duration.humanize()})
      </div>
    );
  });

  const start = moment(timestamp);
  const end = moment(start).add(interval, 'milliseconds');

  let title;
  if (start.day() === end.day()) {
    // if the day is the same, no need to duplicate the date
    title = `${start.format(DateFormat)} - ${end.format(TimeFormat)}`;
  } else {
    title = `${start.format(DateFormat)} - ${end.format(DateFormat)}`;
  }

  return <Popover.Window title={title}>{body}</Popover.Window>;
};

const MAX_NUMBER_OF_CIGAR_PARTS = 20;

const JustACigar = (props) => {
  // reduce number of items to display
  const skipDoesNotExistYet =
    Object.keys(props.stats).length > 1 || props.stats.hasOwnProperty(AllowedStatuses.DoesNotExistYet);

  let totalNumber = 1 - ((skipDoesNotExistYet && props.stats[AllowedStatuses.DoesNotExistYet]) || 0);
  const threshold = totalNumber / MAX_NUMBER_OF_CIGAR_PARTS;
  const items = {};

  Object.entries(props.stats).forEach(([status, value]) => {
    if (value >= threshold && (status !== AllowedStatuses.DoesNotExistYet || !skipDoesNotExistYet)) {
      items[status] = value;
    }
  });

  const events = [];

  const createEvent = (status, index) => {
    const statusClass = StatusToCls[status];
    return {
      class: statusClass ? `event ${statusClass}` : 'event ',
      key: `${status}-${index || 0}`,
    };
  };

  if (Object.keys(items).length === 1) {
    events.push(createEvent(Object.keys(items)[0], 0));
  } else {
    // we skipped some small values, so need to recalculate totalNumber
    totalNumber = Array.from(Object.values(items)).reduce((partialSum, a) => partialSum + a, 0);
    const partSize = Math.ceil((totalNumber * 100) / MAX_NUMBER_OF_CIGAR_PARTS) / 100;

    // the order is matter
    AllowedStatusesList.forEach((status) => {
      if (items.hasOwnProperty(status)) {
        const numberOfEvents = items[status];
        const numberOfParts = Math.ceil(numberOfEvents / partSize);

        const statusItems = Array.from(new Array(numberOfParts).keys()).map((index) => createEvent(status, index));
        events.push(...statusItems);
      }
    });
  }

  // There is no a single event, so object doesn't exist yet
  if (events.length === 0) {
    events.push(createEvent(AllowedStatuses.DoesNotExistYet, 0));
  }

  // if there is only one event, we need to add another one with the same
  // status, since layout expects to see 2 elements to color the whole elem in
  // one color
  if (events.length === 1) {
    events.push({ class: events[0].class, key: 'another-one' });
  }

  const popoverFormatter = props.formatPopover || defaultPopoverForJustACigar;

  return (
    <Popover>
      <Popover.Trigger>
        <div className="day">
          {events.map((event) => (
            <div key={event.key} className={event.class} />
          ))}
        </div>
      </Popover.Trigger>
      {popoverFormatter(props.timestamp, props.stats, props.interval)}
    </Popover>
  );
};

export const StatusChart = (props) => {
  if (props.data === undefined || props.data === null || props.data.length === 0) {
    return <JustADot status={props.status} title={props.statusDescription || props.status} />;
  }
  // calculate the interval between cigars
  let interval;
  if (props.data.length > 1) {
    interval = props.data[1].timestamp - props.data[0].timestamp;
  } else {
    // this should never happen, but let's assign some value to prevent further errors
    interval = 0;
  }

  return (
    <div className="calendar-sticks">
      {props.data.map((item) => (
        <JustACigar
          key={item.timestamp}
          timestamp={item.timestamp}
          stats={item.stats}
          formatPopover={props.formatPopover}
          interval={interval}
        />
      ))}
    </div>
  );
};

StatusChart.propTypes = {
  status: PropTypes.oneOf(AllowedStatusesList).isRequired,
  statusDescription: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      stats: PropTypes.shape(Object.fromEntries(AllowedStatusesList.map((status) => [status, PropTypes.number])))
        .isRequired,
    })
  ),
  formatPopover: PropTypes.func,
};

export const RecordStatusChart = observer((props) => {
  let status;
  let statusDescription;
  if (!props.record.loaded) {
    status = AllowedStatuses.Unknown;
    statusDescription = 'Record status is not loaded yet.';
  } else {
    status = props.record.statusable_1.status;
    statusDescription = props.record.statusable_1.statusDescription;
  }

  return (
    <StatusChart
      status={status}
      statusDescription={statusDescription}
      data={props.data ? props.data.toJSON().stats : undefined}
      formatPopover={props.formatPopover}
    />
  );
});

RecordStatusChart.propTypes = {
  record: PropTypes.object.isRequired,
  data: PropTypes.any,
  formatPopover: PropTypes.func,
};
