import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { withNavigate } from 'lib/router-helper';

import Input from 'components/forms/Input';
import { Spinner } from 'components/Loader';
import { ConfirmationAboutDeleteWindow } from 'components/ModalWindow';
import { InstanceLink } from 'components/Nav';
import { ContainerBox, PageHeader } from 'components/Page';

import { ApplicationStates } from 'Constants';
import {
  VerTableStore,
  ColumnRecordLink,
  ColumnReferences,
  ColumnTimestamp,
  ColumnInventoryRecordStatus,
  ColumnText,
  ColumnModel,
  loadRecodsWithStatusStats,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';
import Icon from 'components/Icon';
import { AgentsListStore } from './AgentsListStore';

const AgentNameFilter = inject('instance')(
  observer((props) => {
    const { name } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      props.store.applyNameFilter(name ? name.toLowerCase() : '');
      navigate(`/i/${props.instance.name}/agents?${props.store.queryString}`);
    }, [name]);

    if (!props.instance.Applications.loaded) {
      return null;
    }

    return (
      <div className="form-box">
        <Input store={props.store.nameFilter} />
      </div>
    );
  })
);

@withNavigate
@inject('instance')
@observer
class AgentStateFilter extends React.Component {
  filterStatus(eventKey) {
    this.props.store.applyStateFilter(eventKey.target.name, eventKey.target.checked);
    this.props.navigate(`/i/${this.props.instance.name}/agents?${this.props.store.queryString}`);
  }

  render() {
    return (
      <form className="form-box">
        <div className="btn-group">
          {Object.entries(ApplicationStates).map(([title, value]) => (
            <div className="btn-group-item" key={title}>
              <input
                type="checkbox"
                className="check"
                name={value}
                id={value}
                defaultChecked={this.props.store.stateFilter.get(value)}
                onChange={(eventKey) => this.filterStatus(eventKey)}
              />
              <label className="btn" htmlFor="active">
                {title}
              </label>
            </div>
          ))}
        </div>
      </form>
    );
  }
}

const AgentsHeader = inject('instance')(
  observer((props) => (
    <div className="filters-box">
      <AgentNameFilter store={props.store} />
      <AgentStateFilter store={props.store} />
    </div>
  ))
);

const AgentsTable = inject(
  'store',
  'instance'
)(
  observer((props) => {
    const query = "inherits('std::system/App:1') AND isNotSet('std::types/Versionable:1.deletedAt')";

    const [store] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 25,
        paginationRowsPerPageOptions: [25, 50, 100],
        selectable: false,
        query: query + (props.filter ? ` AND search('${props.filter}')` : ''),
        columns: [
          ColumnModel.create({}),
          ColumnInventoryRecordStatus.create({ name: 'Status', opts: { width: '100px' } }),
          ColumnRecordLink.create({ name: 'Agent', urlRoute: 'records' }),
          ColumnText.create({ name: 'Type', key: 'std::system/App:1.applicationType' }),
          ColumnText.create({ name: 'Description', key: 'std::system/App:1.description', opts: { grow: 2 } }),
          ColumnReferences.create({ name: 'Admins', keys: ['std::system/App:1.defaultAdmins'] }),
          ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
      })
    );

    useEffect(() => {
      loadRecodsWithStatusStats(store);
      store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    useEffect(() => {
      const newQuery = query + (props.filter ? ` AND search('${props.filter}')` : '');
      if (newQuery !== store.query) {
        store.setQuery(newQuery);
      }
    }, [props.filter]);

    return <Table store={store} />;
  })
);

@inject('instance')
@observer
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.store = AgentsListStore.create({});
  }

  componentDidMount() {
    if (!this.props.instance.Applications.loading) {
      this.props.instance.Applications.fetch();
    }
  }

  deleteApplication = () => {
    this.props.instance.Applications.delete(this.store.confirmDeleteForID, () =>
      this.store.setConfirmDeleteForID(null)
    );
  };

  closeModalWindow = () => {
    this.store.setConfirmDeleteForID(null);
  };

  render() {
    let pageContent = (
      <>
        {this.store.loaded && <AgentsTable filter={this.store.nameFilter.value} />}
        {this.store.confirmDeleteForID && (
          <ConfirmationAboutDeleteWindow
            onClose={this.closeModalWindow}
            onCancel={this.closeModalWindow}
            onDelete={this.deleteApplication}
          />
        )}
      </>
    );

    if (!this.props.instance.Applications.loaded) {
      pageContent = <Spinner />;
    }
    return (
      <ContainerBox>
        <PageHeader title="Agents" documentTitle="Agents">
          {this.props.instance.amIAdmin && (
            <InstanceLink to="/agents/launch" className="btn btn-primary">
              <Icon className="create-new-btn" />
              Create new
            </InstanceLink>
          )}
        </PageHeader>
        <AgentsHeader store={this.store} />
        {pageContent}
      </ContainerBox>
    );
  }
}
