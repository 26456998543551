import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import * as queryString from 'lib/query-string';
import Spinner from 'components/Loader';
import PageLayout from 'pages/Auth/components/PageLayout';
import UserFirstLogin from 'pages/Auth/components/UserFirstLogin';
import OAuth2S from 'pages/Auth/OAuth2S';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
var JustMessage = function (_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "form-box" },
        React.createElement("form", null,
            React.createElement("div", { className: "message-placeholder" }, children))));
};
export default inject('profile')(observer(function (_a) {
    var profile = _a.profile;
    var _b = useState(), form = _b[0], setForm = _b[1];
    var location = useLocation();
    var navigate = useNavigate();
    var params = useParams();
    useEffect(function () {
        var query = queryString.parse(location.search);
        var error = null;
        if (!params.provider || !query.code) {
            error = 'Something is wrong. Please contact ITLook administrator.';
        }
        var newForm = OAuth2S.create({
            provider: params.provider || null,
            code: query.code,
            redirectUrl: query.state,
            error: error,
        });
        newForm.setUpdateURLPath(navigate);
        newForm.registerSetToken(profile.setToken);
        if (!newForm.error) {
            newForm.login();
        }
        setForm(newForm);
    }, []);
    if (form === undefined) {
        return null;
    }
    if (form.firstLogin) {
        return React.createElement(UserFirstLogin, { store: form });
    }
    var item;
    if (form.error) {
        item = form.error;
    }
    else if (form.notRegisteredYet) {
        item = 'You do not have registered account at ITLook yet.';
    }
    else {
        item = React.createElement(Spinner, null);
    }
    return (React.createElement(PageLayout, { title: "OAuth2 callback" },
        React.createElement(JustMessage, null, item)));
}));
