import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import { types, getSnapshot, applySnapshot } from 'mobx-state-tree';

import ApplicationTypes from 'stores/ApplicationTypes';
import Instances from 'stores/Instances/Instances';
import { Models, SchemaDocs } from 'stores/Models';
import Notifications from 'stores/Notifications';
import Organization from 'stores/Organization';
import Profile from 'stores/Profile';
import Settings from 'stores/Settings';
import TransportLayer from 'stores/TransportLayer';
import { TerminalsStore } from 'pages/TerminalsModal/TerminalsS';

export const Store = types
  .model('Store', {
    ApplicationTypes: types.optional(ApplicationTypes, () => ApplicationTypes.create({})),
    Instances: types.optional(Instances, () => Instances.create({})),
    Models: types.optional(Models, () => Models.create({})),
    Notifications: types.optional(Notifications, () => Notifications.create({})),
    Organization: types.optional(Organization, () => Organization.create({})),
    Profile: types.optional(Profile, () => Profile.create({})),
    SchemaDocs: types.optional(SchemaDocs, () => SchemaDocs.create({})),
    Settings: types.optional(Settings, () => Settings.create({})),
    TransportLayer: types.optional(TransportLayer, () => TransportLayer.create({})),
    Terminals: types.optional(TerminalsStore, () => TerminalsStore.create({})),
  })
  .views((self) => ({
    get fullyLoaded() {
      return self.Instances.loaded && self.Instances.getCurrent().token && self.Profile.user;
    },
    get instance() {
      return self.Instances.getCurrent();
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self); // check localstorage after snapshotting to avoid storing invalid token
        self.Profile.loadTokenFromLocalStorage();
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
    };
  });

export const useStores = () => React.useContext(MobXProviderContext);

export default Store.create({});
