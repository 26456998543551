import { types } from 'mobx-state-tree';

import { jwtDecode } from 'jwt-decode';

import requests from 'lib/requests';

import { EmailInputStore, PasswordInputStore } from 'components/forms/Input';
import { makePasswordEqualityValidator } from 'components/forms/Input/PasswordInputS';
import { LoginActions } from 'Constants';

export const PasswordRecoveryForm = types
  .model('PasswordRecoveryForm', {
    email: types.optional(EmailInputStore, () => EmailInputStore.create()),
    error: types.maybeNull(types.string),
    completed: false,
  })
  .actions((self) => ({
    setEmail(email) {
      self.email = email;
      self.error = null;
    },
    setError(message) {
      self.error = message;
    },
    setLoading(value) {
      self.email.setDisabled(value);
    },
    submit(onSuccess, setError) {
      self.setLoading(true);
      requests.POST({
        url: `/m/api/v1/users/${self.email.value}/reset-password`,
        onFailure: (response, errors) => {
          if (setError) {
            setError(errors[1]);
          }
        },
        onSuccess: () => {
          onSuccess();
        },
        onFinish: () => self.setLoading(false),
      });

      self.completed = true;
    },
  }))
  .views((self) => ({
    get filled() {
      return self.email.isDone();
    },
  }));

export const PasswordUpdateForm = types
  .model('PasswordUpdateForm', {
    token: types.maybeNull(types.string),
    newPassword1: types.optional(PasswordInputStore, () =>
      PasswordInputStore.create({ label: 'New password', schema: {} })
    ),
    newPassword2: types.optional(PasswordInputStore, () =>
      PasswordInputStore.create({ label: 'Confirm new password' })
    ),
    error: types.maybeNull(types.string),
    saving: false,
  })
  .volatile(() => ({
    updateURLPath: () => {},
  }))
  .views((self) => ({
    get email() {
      if (self.token) {
        const parsed = jwtDecode(self.token);
        return parsed.sub;
      }
      return null;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.newPassword1.registerOnChangeHandler(self.onPassword1Change);
      self.newPassword2.addInputValidator(makePasswordEqualityValidator(self.newPassword1));
    },
    onPassword1Change() {
      if (self.newPassword2.value !== null) {
        self.newPassword2.validate();
      }
    },
    setToken(token) {
      self.token = token;
    },
    setUpdateURLPath(updateURLPath) {
      self.updateURLPath = updateURLPath;
    },
    setSaving(value) {
      self.saving = value;
      self.newPassword1.setDisabled(value);
      self.newPassword2.setDisabled(value);
    },
    setError(message) {
      self.error = message;
      self.setSaving(false);
    },
    submit() {
      self.setSaving(true);
      requests.POST({
        url: `/m/api/v1/users/${self.email}`,
        authToken: self.token,
        body: { password: self.newPassword1.value },
        onFailure: (response, errors) => {
          self.setError(errors[1]);
        },
        onSuccess: () => {
          self.updateURLPath(`/?action=${LoginActions['password-is-updated'].urlValue}`);
        },
      });
    },
  }));
