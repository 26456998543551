import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';

import AsciinemaPlayer from 'components/AsciinemaPlayer';
import Icon from 'components/Icon';
import { Spinner } from 'components/Loader';

import { Terminal } from 'xterm';
import { TerminalSearch } from 'pages/TerminalsModal/TerminalSearch';
import { FitAddon } from 'xterm-addon-fit';
import { SearchAddon } from 'xterm-addon-search';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { useWindowDimensions } from 'lib/windowSize';

import 'xterm/css/xterm.css';

import 'asciinema-player/dist/bundle/asciinema-player.css';

const XTermViewer = observer((props) => {
  const ref = useRef(null);
  const rootRef = useRef(null);
  const [searchAddon] = useState(new SearchAddon());
  const [fitAddon] = useState(new FitAddon());
  const header = props.cast[0];

  const ALT_START = '\x1B[?1049h';
  const ALT_STOP = '\x1B[?1049l';

  const clearFromAlt = (active, message) => {
    if (active) {
      const altStopsAt = message.indexOf(ALT_STOP);
      if (altStopsAt === -1) {
        return [true, ''];
      }
      return clearFromAlt(false, message.slice(altStopsAt + ALT_STOP.length));
    }

    const altStartsAt = message.indexOf(ALT_START);
    if (altStartsAt === -1) {
      return [false, message];
    }
    const beforeAltMsg = message.slice(0, altStartsAt);
    const afterAltMsg = message.slice(altStartsAt + ALT_START.length);
    const [newActive, clearMessage] = clearFromAlt(true, afterAltMsg);
    return [newActive, beforeAltMsg + clearMessage];
  };

  const [terminal, setTerminaml] = useState(null);

  useEffect(() => {
    if (ref.current && !terminal) {
      const term = new Terminal({
        allowProposedApi: true,
        cursorStyle: 'underline',
        disableStdin: true,
        convertEol: true,
        cols: header.width,
        rows: header.height,
        scrollback: 100000,
        allowTransparency: true,
        fontWeight: 400,
        fontFamily: 'Roboto Mono,Martian Mono,Menlo,Monaco,Consolas,monospace',
        fontSize: 16,
        theme: {
          background: 'rgba(0, 0, 0, 0)',
        },
      });
      term.loadAddon(fitAddon);
      term.loadAddon(searchAddon);
      term.loadAddon(new WebLinksAddon());

      term.open(ref.current);

      let clearMsg = '';
      let altScreen = false;

      props.cast.slice(1).forEach((el) => {
        if (el[1] === 'o' && el[2]) {
          [altScreen, clearMsg] = clearFromAlt(altScreen, el[2]);
          term.write(clearMsg);
        }
      });
      term.resize(50, 50);
      fitAddon.fit();
      setTerminaml(term);
    }
  }, [ref.current, terminal]);

  // Fit plugin for XTerm only works well if the size of parent block is defined
  // For now we have to rely on this hacky way, hopefully we find something better
  // in future.
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [height, setHeight] = useState(windowHeight);
  const [terminalHeight, setTerminalHeight] = useState(windowHeight);
  const [width, setWidth] = useState(windowWidth);

  useEffect(() => {
    const rootTop = rootRef.current.getBoundingClientRect().top;
    const terminalTop = ref.current.getBoundingClientRect().top;
    const bottomPadding = 40;

    if (!props.nested) {
      setHeight(windowHeight - rootTop - bottomPadding);
      setTerminalHeight(windowHeight - terminalTop - bottomPadding);
    } else {
      // MAX height used in styles for nested terminal
      setHeight(600 + terminalTop - rootTop);
      setTerminalHeight(600);
    }

    let leftPadding = 32;
    if (props.nested) {
      // In case of showing terminal for table, we need to reduce width a bit more
      // 38 is minimal amount of padding to remove horizontal scroll bar
      leftPadding += 38;
    }

    setWidth(windowWidth - ref.current.getBoundingClientRect().left - leftPadding);
    if (terminal) {
      fitAddon.fit();
    }
  }, [terminal, windowWidth, windowHeight, rootRef.current]);

  return (
    <div className="terminal-standalone">
      <div className="terminal-grid" style={{ height: height, width: width }} ref={rootRef}>
        <div className="terminal-grid-body">
          <div className="terminal-body-wrapper terminal-read-only">
            <div className="terminal-tools-container">
              <div className="terminal-session-info">Recording of terminal session</div>
              <div className="spacer" />
              <div className="terminal-tools-container">
                <TerminalSearch searchAddon={searchAddon} />
                <button type="button" className="btn btn-default btn-small" onClick={props.showPlayer}>
                  <Icon className="icon replay-session" />
                  Re-play session
                </button>
              </div>
            </div>
            <div className="terminal-window" style={{ height: terminalHeight }}>
              <div ref={ref} className={`terminal-xterm ${props.nested ? 'in-table' : ''}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const AsciinemaModal = observer((props) => {
  return (
    <div className="global-modal" style={{ display: 'block' }}>
      <div className="modal-grid">
        <div className="modal-grid-header">
          <div className="title">Re-playing session</div>
          <div className="action" onClick={props.closePlayer}>
            <Icon className="close-dark" title="Hide terminal window" />
          </div>
        </div>
        <div className="modal-grid-body">
          <AsciinemaPlayer
            data={props.cast}
            idleTimeLimit={2}
            preload
            loop={0}
            speed={2}
            rows={props.session.xtermRows}
            cols={props.session.xtermCols}
          />
        </div>
      </div>
    </div>
  );
});

const TerminalSessionTab = inject(
  'instance',
  'store'
)(
  observer((props) => {
    const SCF = 'std::host/SessionCastFrame:1';

    const session = props.record.session_1;
    const [loaded, setLoaded] = useState(false);
    const [playerVisible, setPlayerVisible] = useState(false);
    const [cast] = useState([
      {
        version: 2,
        width: session.xtermCols,
        height: session.xtermRows,
        timestamp: session.startedAt,
        title: `Session on host ${session.target} by users ${session.user}`,
        env: { TERM: 'xterm-256color', SHELL: session.xtermShell },
      },
    ]);

    const translateEvent = (castFrame) => {
      let data = castFrame.data;
      if (castFrame.event === 'r') {
        // todo (fix this when fix gets fixed in host app)
        data = JSON.parse(data.replace(/'/g, '"'));
        data = `${data.columns}x${data.rows}`;
      }
      return [castFrame.time, castFrame.event, data];
    };

    const loadCast = (scan_cursor) => {
      props.store.TransportLayer.get({
        url: `/i/api/v1/journal`,
        query: {
          inherits: SCF,
          owner: props.record.root_1.id,
          scan_field: 'std::types/Journal:1.order',
          size: 1000,
          scan_cursor: scan_cursor || 0,
        },

        onSuccess: (response, responseData) => {
          cast.push(...responseData.data.results.map((el) => translateEvent(el[SCF])));
          if (responseData.data.has_more) {
            loadCast(responseData.data.next_scan_cursor);
          }
          setLoaded(true);
        },
      });
    };

    useEffect(loadCast, []);

    return (
      <>
        {!loaded && <Spinner />}
        {loaded && <XTermViewer cast={cast} nested={props.nested} showPlayer={() => setPlayerVisible(true)} />}
        {loaded && playerVisible && (
          <AsciinemaModal cast={cast} session={session} closePlayer={() => setPlayerVisible(false)} />
        )}
      </>
    );
  })
);

TerminalSessionTab.suites = (record) => record.session_1;

export default TerminalSessionTab;
