import React, { useEffect, useState } from 'react';

import { PageHeader, ContainerBox } from 'components/Page';
import { Link } from 'react-router-dom';

import {
  VerTableStore,
  ColumnText,
  ColumnModel,
  ColumnRecordLink,
  ColumnReferences,
  ColumnTimestamp,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';

import { observer, inject } from 'mobx-react';
import Icon from 'components/Icon';

export default inject(
  'store',
  'instance'
)(
  observer((props) => {
    const [store] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 25,
        paginationRowsPerPageOptions: [25, 50, 100],
        selectable: false,
        query: "inherits('std::system/User:1')",
        columns: [
          ColumnModel.create({}),
          ColumnRecordLink.create({ name: 'Name', sortKey: 'std::types/Inventory:1.displayName' }),
          ColumnText.create({ name: 'Email', key: 'std::system/User:1.email' }),
          ColumnReferences.create({ name: 'Groups', keys: ['std::system/User:1.memberOf'] }),
          ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
      })
    );

    useEffect(() => {
      store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    return (
      <div>
        <ContainerBox>
          <PageHeader title="Users">
            <Link to="/organization/users" className="btn btn-primary">
              <Icon className="manage-users-btn" />
              Manage
            </Link>
          </PageHeader>
          <Table store={store} />
        </ContainerBox>
      </div>
    );
  })
);
