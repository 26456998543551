import React from 'react';
import { inject, observer } from 'mobx-react';

import { withNavigate } from 'lib/router-helper';

import Input from 'components/forms/Input';
import { EditForm } from 'stores/Instances/Applications';

@withNavigate
@inject('instance')
@observer
class AgentConfigurationTab extends React.Component {
  constructor(props) {
    super(props);
    this.form = EditForm.create({ id: props.record.id });
    this.form.registerOnUpdate(() => {
      const record = this.props.instance.InventoryRecords.getById(
        this.props.record.id,
        () => {
          this.props.navigate(`/i/${this.props.instance.name}/records/${record.id}/app-hosts`);
        },
        true
      );
    });
  }

  render() {
    return (
      <form className="form-box">
        <Input key="name" store={this.form.name} />
        <Input key="description" store={this.form.description} />
        <div className="message-placeholder">{this.form.error}</div>
        <div className="form-item">
          <button
            className="btn-primary"
            type="button"
            disabled={!this.form.hasSomethingToUpdate() || this.form.updatingEntity}
            onClick={() => {
              this.form.tryUpdate(this.props.instance.Applications);
            }}
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

AgentConfigurationTab.suites = (record) => record.app_1 && record.app_1.applicationType === 'std::Hosts:1';

export default AgentConfigurationTab;
