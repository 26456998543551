import React from 'react';
import { observer } from 'mobx-react';

import { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';

export default observer((props) => (
  <div className="form-box">
    <SimpleTextViewer syntax={Syntaxes.json} value={JSON.stringify(props.record.data, null, 4)} />
  </div>
));
