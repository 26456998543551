import React from 'react';
import { types } from 'mobx-state-tree';

import { KeyCodes } from 'Constants';
import { UUID4 } from 'lib/uuid-utils';

const requiredValidator = (value, self) => {
  if (value === '' || value === null) {
    const label = self.label ? self.label : 'Value';
    return `${label} is required field.`;
  }
};

const InputStore = types
  .model('InputStore', {
    /* Define here only customizable properties */

    value: '',

    // whether to remove form-item div or leave it
    thin: false,

    // className to apply for form-item div wrapper
    formItemClassName: types.maybeNull(types.string),

    description: types.optional(types.union(types.undefined, types.null, types.string), undefined),
    error: types.optional(types.union(types.undefined, types.null, types.string), undefined),

    // label configuration
    label: types.maybeNull(types.string),
    labelDescription: types.optional(types.union(types.undefined, types.null, types.string), undefined),
    required: false,

    inputId: types.optional(types.string, () => UUID4()),

    inputClassName: types.maybeNull(types.string),
    // input placeholder
    placeholder: types.optional(types.union(types.undefined, types.null, types.string), undefined),
    // type of input
    inputType: 'text',
    // name of the control, which is submitted with the form data.
    name: types.maybeNull(types.string),
    // additional components that should be places after <input /> component
    autocompleteOptions: types.array(types.string),
    // whether the browser should focus on input component
    autoFocus: types.maybeNull(types.boolean),
    // whether user can change input field
    disabled: false,
    // disables modifications of disabled flag
    ignoreSetDisabledCalls: false,
    // whether trigger blur event (release focus from input) on EnterPress
    releaseFocusOnEnterPress: types.maybeNull(types.boolean),
    // time to wait before triggering onChange handler
    onChangeHandlingDelay: 150,

    // dirty hack to do not pass errors and description to FormItem. It is
    // needed if these items should be displayed in another way
    // FIXME(andreykurilin): split Input component to BaseInput and Input.
    //    The first one should not use FormItem at all.
    doNotShowErrors: false,
    doNotShowDescription: false,

    // inner fields that user/developer (the person who uses this store) should not interact with
    delayedValue: types.maybeNull(types.string),
    focused: false,
    selectedAutocompleteItem: -1,
  })
  .volatile(() => ({
    inputRef: null,
    timer: undefined,
    validators: [],
    onChange: () => {},
    onEnterPress: () => {},
    onFocus: () => {},
    onFocusOut: () => {},
  }))
  .views((self) => ({
    _defaultIsDone() {
      return self.value && self.timer === undefined && !self.error;
    },
    isDone() {
      return self._defaultIsDone();
    },
    get showPlaceholderAfterInput() {
      return self.label && (self.placeholder === undefined || self.placeholder === self.label);
    },
    get formItemLabel() {
      if (self.showPlaceholderAfterInput) {
        return null;
      }
      return self.label;
    },
    get finalInputPlaceholder() {
      if (self.showPlaceholderAfterInput) {
        return self.label;
      }
      if (self.placeholder === false) {
        return undefined;
      }
      return self.placeholder;
    },
    get finalInputClassName() {
      if (self.inputClassName) {
        return self.inputClassName;
      }
      if (self.error) {
        return 'invalid';
      }
      return '';
    },
    get inputStyle() {
      // can be overriden by inheritors to provide custom styles
      return undefined;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.inputRef = React.createRef();

      if (self.required) {
        self.addInputValidator((curValue) => requiredValidator(curValue, self));
      }
    },
    componentDidMount() {
      if (self.value) {
        self.setValue(self.value);
      }
    },
    registerOnChangeHandler(func) {
      self.onChange = func;
    },
    registerOnEnterPressHandler(func) {
      self.onEnterPress = func;
    },
    registerOnFocusHandler(func) {
      self.onFocus = func;
    },
    registerOnFocusOutHandler(func) {
      self.onFocusOut = func;
    },
    addInputValidator(func) {
      self.validators.push(func);
    },
    setValue(value) {
      self.value = value;
      // inputRef.current == null when the component is not attached yet
      if (self.inputRef.current !== null) {
        if (self.timer) {
          clearTimeout(self.timer);
        }
        self.inputRef.current.value = value;
      }
    },
    setError(errorMessage, validatedValue) {
      if (validatedValue === undefined || self.value === validatedValue) {
        self.error = errorMessage;
      }
    },
    setInputRef(value) {
      self.inputRef = value;
    },
    setDisabled(value) {
      if (self.ignoreSetDisabledCalls) {
        return;
      }
      self.disabled = value;
    },
    validate() {
      const currentValue = self.value;
      self.error = null;

      self.validators.forEach((validator) => {
        if (!self.error) {
          const errorMessageOrPromise = validator(currentValue);
          if (errorMessageOrPromise && typeof errorMessageOrPromise === 'string') {
            self.setError(errorMessageOrPromise, currentValue);
          } else {
            Promise.resolve(errorMessageOrPromise).then((errorMessage) => {
              if (errorMessage) {
                self.setError(errorMessage, currentValue);
              }
            });
          }
        }
      });
    },
    handleOnChangeDelayed(e) {
      self.value = e.target.value;
      self.validate();
      self.onChange(e.target.value, e);
      self.timer = undefined;
    },
    handleOnChange(e) {
      // if user entered something, selected item from suggestion should be reset
      self.selectedAutocompleteItem = -1;
      self.delayedValue = e.target.value;
      if (self.timer !== undefined) {
        clearTimeout(self.timer);
      }
      self.timer = setTimeout(() => self.handleOnChangeDelayed(e), self.onChangeHandlingDelay);
    },
    handleOnFocus(e) {
      self.focused = true;
      self.onFocus(e);
    },
    handleOnFocusOutDelayed() {
      self.focused = false;
    },
    handleOnFocusOut(e) {
      if (self.autocompleteOptions) {
        // onFocusOut/onBlur event is triggered before onClick event. If we do not give some delay before changing
        // 'focused' state, Suggestion window disappear before possible onClick event fires on Suggestion item.
        setTimeout(self.handleOnFocusOutDelayed, 100);
      } else {
        self.focused = false;
      }

      self.onFocusOut(e);
    },
    handleOnAutoCompleteItemClick(itemIndex) {
      // reset any delayed change
      if (self.timer !== undefined) {
        clearTimeout(self.timer);
        self.timer = undefined;
      }
      // take selected item and put it everywhere
      const selectedItem = self.autocompleteOptions[itemIndex];
      self.setValue(selectedItem);
      self.onChange(selectedItem);
      self.selectedAutocompleteItem = -1;

      self.inputRef.current.blur();
    },
    handleOnKeyDown(e) {
      const keycode = e.keyCode || e.which;
      if (keycode === KeyCodes.ENTER) {
        e.preventDefault();
        e.stopPropagation();

        if (self.timer !== undefined) {
          clearTimeout(self.timer);
          if (self.onChange) {
            self.onChange(self.delayedValue);
          }
        }
        if (self.selectedAutocompleteItem !== -1) {
          self.handleOnAutoCompleteItemClick(self.selectedAutocompleteItem);
        } else {
          self.onEnterPress(self.delayedValue);
          if (self.releaseFocusOnEnterPress) {
            self.inputRef.current.blur();
          }
        }
      }

      if (self.autocompleteOptions) {
        if (keycode === KeyCodes.ARROW_DOWN) {
          self.selectedAutocompleteItem = (self.selectedAutocompleteItem + 1) % self.autocompleteOptions.length;
        } else if (keycode === KeyCodes.ARROW_UP) {
          if (self.selectedAutocompleteItem === -1) {
            // select last item
            self.selectedAutocompleteItem = self.autocompleteOptions.length - 1;
          } else {
            self.selectedAutocompleteItem -= 1;
          }
        } else if (
          (keycode === KeyCodes.TAB || keycode === KeyCodes.ARROW_RIGHT) &&
          self.selectedAutocompleteItem !== -1
        ) {
          self.handleOnAutoCompleteItemClick(self.selectedAutocompleteItem);
        }
      }
    },
  }));

export default InputStore;
