import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';

import { PageHeader, ContainerBox } from 'components/Page';
import { SidedPanel, LeftPanel } from 'components/layout/Panels';
import Checkbox from 'components/Checkbox';

import { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';

const installStepsWithVenv = `
# Install ansible galaxy collection',
'ansible-galaxy collection install itlook.it',

# Install and source virtualenv
virtualenv itlook',
. ./itlook/bin/activate

# Install ansible and other python requirements
pip install ansible requests websocket-client "PyJWT>=2.0.0"
`.trim();

const installStepsSystemWide = `
# Install ansible galaxy collection
ansible-galaxy collection install itlook.it

# Install python requirements
pip install requests websocket-client "PyJWT>=2.0.0
`.trim();

const AnsibleConf = `
[defaults]
callback_whitelist = itlook.it.callback
callbacks_enabled = itlook.it.callback

[ssh_connection]
pipelining = True

[connection]
pipelining = True
`.trim();

const DummyPlaybook = `
---
- name: test
  hosts: itlook_managed
  become: false
  gather_facts: false
  tasks:
    - name: LS
      command: ls -lah
`.trim();

const DummyAnsibleCommand = `
ansible-inventory -i itlook.yaml --graph
ansible-playbook test.yaml -i itlook.yaml
`.trim();

export default inject('instance')(
  observer((props) => {
    const [advanceFilters, setAdvanceFilters] = useState(false);

    const [useVenv, setUseVenv] = useState(false);

    return (
      <ContainerBox>
        <PageHeader title="Ansible Integration Setup" documentTitle="Ansible" />
        <SidedPanel className="ansible">
          <LeftPanel>
            <form>
              <h4>1. Install Requirements</h4>
              <h5> ITLook uses custom inventory and connection plugins that have to be installed</h5>
              <div className="form-box">
                <fieldset className="fieldset">
                  <Checkbox label="Use Virtualenv" checked={useVenv} onChange={() => setUseVenv(!useVenv)} />
                </fieldset>

                <SimpleTextViewer
                  key="installation-steps2"
                  label={`Install requirements ${useVenv ? 'inside virtual env' : 'system wide'}`}
                  syntax={Syntaxes.bash}
                  filename="install-ansible.sh"
                  value={useVenv ? installStepsWithVenv : installStepsSystemWide}
                />
              </div>

              <h4>2. Generate ITLook Persistent Key</h4>
              <h5>Execute commands below to obtain non expiring token</h5>
              <div className="form-box">
                <SimpleTextViewer
                  key="env-vars"
                  label="Commands to execute"
                  syntax={Syntaxes.bash}
                  value={[
                    '# Your short living token for API access.',
                    `export ITLOOK_TOKEN="${props.instance.token}"`,
                    '',
                    '# Generate non expiring key for API access',
                    "export ITLOOK_KEY=$(curl -X 'POST' \\",
                    `'${props.instance.origin}/i/api/v1/auth/access-key-sets?format=base64' \\`,
                    "-H 'accept: application/json' \\",
                    "-H 'Content-Type: application/json' \\",
                    '-H "Authorization: Bearer ${ITLOOK_TOKEN}" \\',
                    '-d \'{"description": "ansible key"}\')',
                  ].join('\n')}
                  filename="export-key.sh"
                />
              </div>

              <h4>3. Update ansible configuration to use ITLook </h4>
              <h5> Add lines to ansible.cfg</h5>
              <div className="form-box">
                <SimpleTextViewer
                  key="ansible.cfg"
                  label="Save Ansible config to ansible.cfg"
                  syntax={Syntaxes.ini}
                  value={AnsibleConf}
                  filename="ansible.cfg"
                />
              </div>

              <h4>4. Create ITLook dynamic inventory</h4>
              <h5>Create dynamic inventory itlook.yaml</h5>
              <div className="form-box">
                <fieldset className="fieldset">
                  <Checkbox
                    label="Show advance configuration"
                    checked={advanceFilters}
                    onChange={() => setAdvanceFilters(!advanceFilters)}
                  />
                </fieldset>
                <SimpleTextViewer
                  key={`itlook.yaml-${advanceFilters}`}
                  label="Save dynamic inventory to itlook.yaml"
                  syntax={Syntaxes.yaml}
                  value={[
                    '---',
                    'plugin: "itlook.it.inventory"',
                    `itlook_url: "${window.location.origin}"`,
                    '',
                    '# Put itlook key here, otherwise export it via env variable.',
                    '# itlook_key: your_key',
                    ...((advanceFilters && [
                      '',
                      '# Type of targets: `containers`, `hosts` or `both`.',
                      'type: both',
                      '',
                      '# If specified, only those agents are going to be used.',
                      '# agents:',
                      '#   - AGENT_ID1',
                      '#   - AGENT_ID2',
                      '',
                      '# Targets with these labels will be included to inventory.',
                      '# include_labels:',
                      '#   - label-1',
                      '#   - label-2',
                      '',
                      '# Targets with these labels will be excluded from inventory.',
                      '# exclude_labels:',
                      '#   - label-3',
                      '#   - label-4',
                    ]) ||
                      []),
                  ].join('\n')}
                />
              </div>
              <h4>5. Use Ansible playbooks as is</h4>
              <h5>Create playbook test.yaml</h5>
              <div className="form-box">
                <SimpleTextViewer key="test.yaml" label="test.yaml" syntax={Syntaxes.yaml} value={DummyPlaybook} />
              </div>
              <h5>Run usual ansible commands with itlook inventory</h5>
              <div className="form-box">
                <SimpleTextViewer
                  key="ansible-run"
                  label="Execute"
                  syntax={Syntaxes.bash}
                  value={DummyAnsibleCommand}
                />
              </div>
            </form>
          </LeftPanel>
        </SidedPanel>
      </ContainerBox>
    );
  })
);
