import 'regenerator-runtime/runtime';

import React, { useRef, useEffect } from 'react';

import { observer } from 'mobx-react';

import Spinner from 'components/Loader';

const XTermConnecting = observer(() => {
  return <Spinner />;
});

const XTermError = observer((props) => {
  return <p>Terminal Is Unavailable! {props.error}</p>;
});

const XTerm = observer((props) => {
  const session = props.session;
  const connection = session.connection;
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      connection.ensureConnected();
    }
    if (connection.term) {
      connection.term.focus();
    }
  }, [session.id, connection.loading, connection.term, ref]);

  return (
    <div className="terminal-window">
      {connection.loading && <XTermConnecting />}
      {connection.error && <XTermError error={connection.errorMsg} />}
      <div ref={ref} id={session.id} style={{ height: '100%' }} />
    </div>
  );
});

export { XTerm };
