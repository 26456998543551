import { types } from 'mobx-state-tree';

import requests from 'lib/requests';

import { EmailInputStore, InputStore, PasswordInputStore } from 'components/forms/Input';

export default types
  .model('SignUpForm', {
    organizationName: types.optional(InputStore, () =>
      InputStore.create({ label: 'Organization', description: 'Short name of organization' })
    ),
    organizationDisplayName: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Organization display name',
        description: 'Optional display name of Organization',
      })
    ),
    firstName: types.optional(InputStore, () => InputStore.create({ label: 'First name' })),
    lastName: types.optional(InputStore, () => InputStore.create({ label: 'Last name' })),
    email: types.optional(EmailInputStore, () => EmailInputStore.create({})),
    password: types.optional(PasswordInputStore, () => PasswordInputStore.create({})),

    // the process of creating an organization
    creating: false,
  })
  .actions((self) => ({
    validateOrganizationNameExistenceInTheSystem(value) {
      if (!value) {
        return false;
      }
      const newPromise = new Promise((resolve) => {
        const apiUrl = `/p/api/v1/organizations/check/${value}`;
        requests.GET({
          url: apiUrl,
          onFailure: (response, errors) => resolve(errors[0]),
          onSuccess: () => resolve(false),
        });
      });

      return newPromise;
    },
    afterCreate() {
      self.organizationName.addInputValidator(self.validateOrganizationNameExistenceInTheSystem);
    },
    signup(onSuccess) {
      self.setCreatingFlag(true);
      const body = {
        password: self.password.value,
        email: self.email.value,
        first_name: self.firstName.value,
        last_name: self.lastName.value,
        name: self.organizationName.value,
      };
      if (self.organizationDisplayName.value) {
        body.display_name = self.organizationDisplayName.value;
      }

      requests.POST({
        url: '/p/api/v1/organizations/',
        body: body,
        onSuccess: onSuccess,
        onFailure: (response, errors) => {
          if (errors.length > 1) {
            self.setOrganizationNameError(errors[1]);
          } else {
            self.setOrganizationNameError(errors[0]);
          }
        },
        onFinish: () => self.setCreatingFlag(false),
      });
    },
    setCreatingFlag(value) {
      self.creating = value;
      self.organizationName.setDisabled(value);
      self.organizationDisplayName.setDisabled(value);
      self.firstName.setDisabled(value);
      self.lastName.setDisabled(value);
      self.email.setDisabled(value);
      self.password.setDisabled(value);
    },
  }))
  .views((self) => ({
    get error() {
      if (self.organizationNameFormatError !== null) {
        return self.organizationNameFormatError;
      }
      return self.emailFormatError;
    },
    get filled() {
      return (
        self.email.isDone() &&
        self.password.isDone() &&
        self.firstName.isDone() &&
        self.lastName.isDone() &&
        self.organizationName.isDone()
      );
    },
  }));
