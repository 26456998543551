import React from 'react';
import { inject, observer } from 'mobx-react';
import * as ContentHeader from 'components/ContentHeader';
import { InstanceLink } from 'components/Nav';
import { Labels } from 'pages/Instances/RecordView/Header/CommonItems';
export default inject('instance')(observer(function (props) {
    if (!props.scriptRecord.loaded || !props.scriptRecord.fs_node_1.isLoaded) {
        return null;
    }
    var name = props.scriptRecord.script_1.path;
    if (props.scriptRunRecord) {
        name = React.createElement(InstanceLink, { to: "/records/".concat(props.scriptRecord.id) }, name);
    }
    return (React.createElement(ContentHeader.Container, null,
        React.createElement(ContentHeader.Column, null,
            React.createElement(ContentHeader.Item, null,
                React.createElement("span", null, "Script Name:"),
                name)),
        React.createElement(ContentHeader.Column, null,
            React.createElement(ContentHeader.Item, null,
                React.createElement("span", null, "Executor / Command:"),
                " ",
                props.scriptRecord.script_1.command)),
        React.createElement(ContentHeader.Column, null,
            React.createElement(ContentHeader.Item, null,
                React.createElement(Labels, { record: props.scriptRecord })))));
}));
