import { types } from 'mobx-state-tree';

import { UserRoles, UserStates } from 'Constants';
import { InputStore, EmailInputStore, DropdownSelectorStore } from 'components/forms/Input';

export default types
  .model('UserInviteForm', {
    email: types.optional(EmailInputStore, () => EmailInputStore.create()),
    firstName: types.optional(InputStore, () => InputStore.create({ label: 'First name' })),
    lastName: types.optional(InputStore, () => InputStore.create({ label: 'Last name' })),
    role: types.optional(DropdownSelectorStore, () =>
      DropdownSelectorStore.create({
        label: 'Role',
        options: Object.values(UserRoles).map((userRole) => ({ value: userRole })),
        noDefaultPlaceholder: true,
        selected: UserRoles.USER,
      })
    ),
    errors: types.array(types.string),
    // the process of creating an user
    requesting: false,
  })
  .volatile(() => ({
    usersStore: null,
    navigate: () => {},
  }))
  .actions((self) => ({
    linkNavigator(navigate) {
      self.navigate = navigate;
    },
    linkUsersStore(store) {
      self.usersStore = store;
    },
    setRequestingFlag(value) {
      self.requesting = value;
      self.email.setDisabled(value);
      self.firstName.setDisabled(value);
      self.lastName.setDisabled(value);
      self.role.setDisabled(value);
    },
    setRequestError(errors) {
      self.errors = errors;
      self.requesting = false;
    },
    onSuccess() {
      self.navigate('/organization/users');
    },
    onFailure(resp, errors) {
      self.setRequestError(errors);
      self.setRequestingFlag(false);
    },
    sendInvitation() {
      self.setRequestingFlag(true);
      self.usersStore.invite(
        self.email.value,
        self.firstName.value,
        self.lastName.value,
        self.role.value,
        self.onSuccess,
        self.onFailure
      );
    },
    validateEmailExistenceInTheSystem(value) {
      if (!value) {
        return false;
      }
      const newPromise = new Promise((resolve) => {
        const onFetchComplete = (user) => {
          if (user !== undefined) {
            if (user.state === UserStates.INVITED) {
              resolve('The user with such email already invited.');
            } else {
              resolve('The user with such email already exists.');
            }
          }
          resolve(false);
        };

        self.usersStore.getFromAPI(value, onFetchComplete);
      });

      return newPromise;
    },
    afterCreate() {
      self.email.addInputValidator(self.validateEmailExistenceInTheSystem);
    },
  }))
  .views((self) => ({
    get filled() {
      return self.email.isDone() && self.firstName.isDone() && self.lastName.isDone();
    },
  }));
